'use strict';

var cov_qt02jedh3 = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/shared/js/contenteditable.directive.js',
        hash = '1719d8ecdf8195549c91df92411bf07200dafb6a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/shared/js/contenteditable.directive.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 27,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 3,
                    column: 4
                },
                end: {
                    line: 26,
                    column: 7
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 13,
                    column: 58
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 14,
                    column: 59
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 18
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 20
                },
                end: {
                    line: 18,
                    column: 59
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 23,
                    column: 19
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 20
                },
                end: {
                    line: 22,
                    column: 39
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 27,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 34
                    },
                    end: {
                        line: 6,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 46
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 18
                    },
                    end: {
                        line: 11,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 60
                    },
                    end: {
                        line: 24,
                        column: 13
                    }
                },
                line: 11
            },
            '3': {
                name: 'read',
                decl: {
                    start: {
                        line: 12,
                        column: 25
                    },
                    end: {
                        line: 12,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 32
                    },
                    end: {
                        line: 15,
                        column: 17
                    }
                },
                line: 12
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 17,
                        column: 34
                    },
                    end: {
                        line: 17,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 46
                    },
                    end: {
                        line: 19,
                        column: 17
                    }
                },
                line: 17
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 21,
                        column: 50
                    },
                    end: {
                        line: 21,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 62
                    },
                    end: {
                        line: 23,
                        column: 17
                    }
                },
                line: 21
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 14,
                        column: 33
                    },
                    end: {
                        line: 14,
                        column: 57
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 14,
                        column: 33
                    },
                    end: {
                        line: 14,
                        column: 51
                    }
                }, {
                    start: {
                        line: 14,
                        column: 55
                    },
                    end: {
                        line: 14,
                        column: 57
                    }
                }],
                line: 14
            },
            '1': {
                loc: {
                    start: {
                        line: 18,
                        column: 33
                    },
                    end: {
                        line: 18,
                        column: 57
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 18,
                        column: 33
                    },
                    end: {
                        line: 18,
                        column: 51
                    }
                }, {
                    start: {
                        line: 18,
                        column: 55
                    },
                    end: {
                        line: 18,
                        column: 57
                    }
                }],
                line: 18
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_qt02jedh3.s[0]++;
(function () {
    'use strict';

    cov_qt02jedh3.f[0]++;
    cov_qt02jedh3.s[1]++;
    angular
    /* eslint-disable prefer-arrow-callback */
    .module('app').directive("contenteditable", function () {
        cov_qt02jedh3.f[1]++;
        cov_qt02jedh3.s[2]++;

        return {
            restrict: "A",
            require: "ngModel",
            /* eslint-disable babel/object-shorthand */
            link: function link(scope, element, attrs, ngModel) {
                cov_qt02jedh3.f[2]++;

                function read() {
                    cov_qt02jedh3.f[3]++;
                    cov_qt02jedh3.s[3]++;

                    ngModel.$setViewValue(element.text());
                    cov_qt02jedh3.s[4]++;
                    element.text((cov_qt02jedh3.b[0][0]++, ngModel.$viewValue) || (cov_qt02jedh3.b[0][1]++, ""));
                }

                cov_qt02jedh3.s[5]++;
                ngModel.$render = function () {
                    cov_qt02jedh3.f[4]++;
                    cov_qt02jedh3.s[6]++;

                    element.text((cov_qt02jedh3.b[1][0]++, ngModel.$viewValue) || (cov_qt02jedh3.b[1][1]++, ""));
                };

                cov_qt02jedh3.s[7]++;
                element.bind("blur keyup change", function () {
                    cov_qt02jedh3.f[5]++;
                    cov_qt02jedh3.s[8]++;

                    scope.$apply(read);
                });
            }
        };
    });
})();