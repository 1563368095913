'use strict';

var cov_24l8wdsr07 = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/homeView/homeView.js',
        hash = '6ce508fde6cbc5041e76d7032b2a05fc89db21dd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/homeView/homeView.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 3
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_24l8wdsr07.s[0]++;
angular.module('app').component('homeView', {
    templateUrl: 'app/components/homeView/homeView.html',
    bindings: {
        welcomeMsg: '<'
    }
});