'use strict';

var cov_1ir0gzch6a = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/index.js',
        hash = '8b5bc019b70cc27b3a1c049e2c4a89cde081d004',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 19
                }
            },
            '1': {
                start: {
                    line: 35,
                    column: 4
                },
                end: {
                    line: 35,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 36,
                    column: 4
                },
                end: {
                    line: 38,
                    column: 6
                }
            },
            '3': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 36
                }
            },
            '4': {
                start: {
                    line: 44,
                    column: 26
                },
                end: {
                    line: 59,
                    column: 5
                }
            },
            '5': {
                start: {
                    line: 60,
                    column: 4
                },
                end: {
                    line: 62,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 64,
                    column: 25
                },
                end: {
                    line: 80,
                    column: 5
                }
            },
            '7': {
                start: {
                    line: 81,
                    column: 4
                },
                end: {
                    line: 83,
                    column: 26
                }
            },
            '8': {
                start: {
                    line: 85,
                    column: 23
                },
                end: {
                    line: 100,
                    column: 5
                }
            },
            '9': {
                start: {
                    line: 101,
                    column: 4
                },
                end: {
                    line: 103,
                    column: 24
                }
            },
            '10': {
                start: {
                    line: 105,
                    column: 4
                },
                end: {
                    line: 108,
                    column: 35
                }
            }
        },
        fnMap: {
            '0': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 34,
                        column: 9
                    },
                    end: {
                        line: 34,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 55
                    },
                    end: {
                        line: 39,
                        column: 1
                    }
                },
                line: 34
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 36,
                        column: 31
                    },
                    end: {
                        line: 36,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 43
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 36
            },
            '2': {
                name: 'theme',
                decl: {
                    start: {
                        line: 42,
                        column: 9
                    },
                    end: {
                        line: 42,
                        column: 14
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 35
                    },
                    end: {
                        line: 109,
                        column: 1
                    }
                },
                line: 42
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1ir0gzch6a.s[0]++;
angular.module('app', ['ui.router', 'restmod', 'restmod.styles.drfPaged', 'ngMaterial', 'ngSanitize', 'satellizer', 'ngFileUpload', 'cgBusy', 'angularMoment', 'angular-loading-bar', 'angular.filter', 'ui.mask', 'ngWig'])
/* eslint-disable no-undef */
.constant('moment', moment).constant('AppSettings', {
    appUrl: 'https://admin.coliseu.soloweb.pt',
    siteUrl: 'https://site.coliseu.soloweb.pt',
    apiUrl: 'https://api.coliseu.soloweb.pt',
    mediaUrl: 'https://api.coliseu.soloweb.pt/static/media',
    graphUrl: 'http://localhost:4040/graph'
}).value('cgBusyDefaults', {
    message: '',
    templateUrl: 'app/shared/html/cgbusy/cgbusy.template.html'
}).run(runBlock).config(theme);

/** @ngInject */
function runBlock($rootScope, AppSettings, $mdSidenav) {
    cov_1ir0gzch6a.f[0]++;
    cov_1ir0gzch6a.s[1]++;

    $rootScope.AppSettings = AppSettings; // You can now access AppSettings from any component with $root.AppSettings
    cov_1ir0gzch6a.s[2]++;
    $rootScope.toggleSideNav = function () {
        cov_1ir0gzch6a.f[1]++;
        cov_1ir0gzch6a.s[3]++;

        $mdSidenav('left').toggle();
    };
}

/** @ngInject */
function theme($mdThemingProvider) {
    cov_1ir0gzch6a.f[2]++;

    /* eslint-disable quote-props */
    var customPrimary = (cov_1ir0gzch6a.s[4]++, {
        '50': '#626262',
        '100': '#555555',
        '200': '#484848',
        '300': '#3b3b3b',
        '400': '#2f2f2f',
        '500': '#222',
        '600': '#151515',
        '700': '#080808',
        '800': '#000000',
        '900': '#000000',
        'A100': '#6e6e6e',
        'A200': '#7b7b7b',
        'A400': '#888888',
        'A700': '#000000'
    });
    cov_1ir0gzch6a.s[5]++;
    $mdThemingProvider.definePalette('customPrimary', customPrimary);

    var customAccent = (cov_1ir0gzch6a.s[6]++, {
        '50': '#000000',
        '100': '#000f14',
        '200': '#00222e',
        '300': '#003447',
        '400': '#004761',
        '500': '#00597a',
        '600': '#007fad',
        '700': '#0091c7',
        '800': '#00a4e0',
        '900': '#00b6fa',
        'A100': '#007fad',
        'A200': '#006C94',
        'A400': '#00597a',
        'A700': '#14c0ff',
        'contrastDefaultColor': 'light'
    });
    cov_1ir0gzch6a.s[7]++;
    $mdThemingProvider.definePalette('customAccent', customAccent);

    var customWarn = (cov_1ir0gzch6a.s[8]++, {
        '50': '#ffb280',
        '100': '#ffa266',
        '200': '#ff934d',
        '300': '#ff8333',
        '400': '#ff741a',
        '500': '#ff6400',
        '600': '#e65a00',
        '700': '#cc5000',
        '800': '#b34600',
        '900': '#993c00',
        'A100': '#ffc199',
        'A200': '#ffd1b3',
        'A400': '#ffe0cc',
        'A700': '#803200'
    });
    cov_1ir0gzch6a.s[9]++;
    $mdThemingProvider.definePalette('customWarn', customWarn);

    cov_1ir0gzch6a.s[10]++;
    $mdThemingProvider.theme('default').primaryPalette('customPrimary').accentPalette('customAccent').warnPalette('customWarn');
}