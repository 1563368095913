'use strict';

var cov_10zlcp95fa = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/inputWithError/inputWithError.js',
        hash = 'bf7e370e174260d1ea018a413b456d7b51e31bfd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/inputWithError/inputWithError.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 5,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_10zlcp95fa.s[0]++;
angular.module('app').component('inputWithError', {
    templateUrl: 'app/components/inputWithError/inputWithError.html'
});