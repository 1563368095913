'use strict';

var cov_6nho2jgla = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/endpointCrudView/endpointCrudView.js',
        hash = 'd5a01fac18d9a242c1a2f48eb2c89656cd2fd246',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/endpointCrudView/endpointCrudView.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 9
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 12
                },
                end: {
                    line: 5,
                    column: 50
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 104
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 35
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 29
                }
            },
            '7': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 39
                }
            },
            '8': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 9
                }
            },
            '9': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 25
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 42
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 30
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 9
                }
            },
            '14': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 57
                }
            },
            '15': {
                start: {
                    line: 41,
                    column: 12
                },
                end: {
                    line: 41,
                    column: 51
                }
            },
            '16': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 30
                }
            },
            '17': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 71
                }
            },
            '18': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 79
                }
            },
            '19': {
                start: {
                    line: 55,
                    column: 25
                },
                end: {
                    line: 55,
                    column: 60
                }
            },
            '20': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 32
                }
            },
            '21': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 9
                }
            },
            '22': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 61,
                    column: 33
                }
            },
            '23': {
                start: {
                    line: 63,
                    column: 22
                },
                end: {
                    line: 63,
                    column: 42
                }
            },
            '24': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 28
                }
            },
            '25': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 32
                }
            },
            '26': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 30
                }
            },
            '27': {
                start: {
                    line: 73,
                    column: 0
                },
                end: {
                    line: 85,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 26
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 14
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 44
                    },
                    end: {
                        line: 15,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 72
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 30
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 18
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 34
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 25
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 46
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 26
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                line: 50
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 54,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 23
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                line: 54
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 59,
                        column: 4
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 19
                    },
                    end: {
                        line: 65,
                        column: 5
                    }
                },
                line: 59
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 67,
                        column: 4
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 19
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                line: 67
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                }, {
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                }],
                line: 4
            },
            '1': {
                loc: {
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                }, {
                    start: {
                        line: 8,
                        column: 8
                    },
                    end: {
                        line: 10,
                        column: 9
                    }
                }],
                line: 8
            },
            '2': {
                loc: {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }, {
                    start: {
                        line: 27,
                        column: 8
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                }],
                line: 27
            },
            '3': {
                loc: {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 42,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 42,
                        column: 9
                    }
                }, {
                    start: {
                        line: 37,
                        column: 8
                    },
                    end: {
                        line: 42,
                        column: 9
                    }
                }],
                line: 37
            },
            '4': {
                loc: {
                    start: {
                        line: 60,
                        column: 8
                    },
                    end: {
                        line: 62,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 60,
                        column: 8
                    },
                    end: {
                        line: 62,
                        column: 9
                    }
                }, {
                    start: {
                        line: 60,
                        column: 8
                    },
                    end: {
                        line: 62,
                        column: 9
                    }
                }],
                line: 60
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EventsListViewController = function () {
    function EventsListViewController() {
        _classCallCheck(this, EventsListViewController);
    }

    _createClass(EventsListViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_6nho2jgla.f[0]++;
            cov_6nho2jgla.s[0]++;

            if (!this.items.$params) {
                cov_6nho2jgla.b[0][0]++;
                cov_6nho2jgla.s[1]++;

                this.items.$params = { ordering: null };
            } else {
                cov_6nho2jgla.b[0][1]++;
            }

            cov_6nho2jgla.s[2]++;
            if (!this.visibleFields) {
                cov_6nho2jgla.b[1][0]++;
                cov_6nho2jgla.s[3]++;

                throw new Error("Missing visibleFields. Should be in format {fieldKey: {displayName: ''}}");
            } else {
                cov_6nho2jgla.b[1][1]++;
            }
            cov_6nho2jgla.s[4]++;
            this._parseVisibleFields();
        }
    }, {
        key: '_parseVisibleFields',
        value: function _parseVisibleFields() {
            var _this = this;

            cov_6nho2jgla.f[1]++;
            cov_6nho2jgla.s[5]++;

            angular.forEach(this.visibleFields, function (fieldOptions, fieldKey) {
                cov_6nho2jgla.f[2]++;
                cov_6nho2jgla.s[6]++;

                _this.visibleFields[fieldKey] = angular.merge({
                    displayType: 'TEXT'
                }, fieldOptions);
            });
        }
    }, {
        key: 'isFunction',
        value: function isFunction(obj) {
            cov_6nho2jgla.f[3]++;
            cov_6nho2jgla.s[7]++;

            return angular.isFunction(obj);
        }
    }, {
        key: 'applyFilter',
        value: function applyFilter(field, value) {
            cov_6nho2jgla.f[4]++;
            cov_6nho2jgla.s[8]++;

            if (value === 'null') {
                cov_6nho2jgla.b[2][0]++;
                cov_6nho2jgla.s[9]++;

                value = null;
            } else {
                cov_6nho2jgla.b[2][1]++;
            }
            cov_6nho2jgla.s[10]++;
            this.items.$params[field] = value;
            cov_6nho2jgla.s[11]++;
            this.items.$refresh();
        }
    }, {
        key: 'sortBy',
        value: function sortBy(field) {
            cov_6nho2jgla.f[5]++;
            cov_6nho2jgla.s[12]++;

            field = field.toUnderscore();
            /* eslint-disable dot-notation */
            cov_6nho2jgla.s[13]++;
            if (this.items.$params['ordering'] === field) {
                cov_6nho2jgla.b[3][0]++;
                cov_6nho2jgla.s[14]++;

                /* eslint-disable prefer-template */
                this.items.$params['ordering'] = '-' + field;
            } else {
                cov_6nho2jgla.b[3][1]++;
                cov_6nho2jgla.s[15]++;

                this.items.$params['ordering'] = field;
            }
            cov_6nho2jgla.s[16]++;
            this.items.$refresh();
        }
    }, {
        key: 'isAscendingBy',
        value: function isAscendingBy(field) {
            cov_6nho2jgla.f[6]++;
            cov_6nho2jgla.s[17]++;

            return this.items.$params['ordering'] === field.toUnderscore();
        }
    }, {
        key: 'isDescendingBy',
        value: function isDescendingBy(field) {
            cov_6nho2jgla.f[7]++;
            cov_6nho2jgla.s[18]++;

            return this.items.$params['ordering'] === '-' + field.toUnderscore();
        }
    }, {
        key: 'goToPreviousPage',
        value: function goToPreviousPage() {
            cov_6nho2jgla.f[8]++;

            var previous = (cov_6nho2jgla.s[19]++, Math.max(1, this.items.$page - 1));
            cov_6nho2jgla.s[20]++;
            this.goToPage(previous);
        }
    }, {
        key: 'goToNextPage',
        value: function goToNextPage() {
            cov_6nho2jgla.f[9]++;
            cov_6nho2jgla.s[21]++;

            if (!this.items.$page) {
                cov_6nho2jgla.b[4][0]++;
                cov_6nho2jgla.s[22]++;

                this.items.$page = 0;
            } else {
                cov_6nho2jgla.b[4][1]++;
            }
            var next = (cov_6nho2jgla.s[23]++, this.items.$page + 1);
            cov_6nho2jgla.s[24]++;
            this.goToPage(next);
        }
    }, {
        key: 'goToPage',
        value: function goToPage(page) {
            cov_6nho2jgla.f[10]++;
            cov_6nho2jgla.s[25]++;

            this.items.$page = page;
            cov_6nho2jgla.s[26]++;
            this.items.$refresh();
        }
    }]);

    return EventsListViewController;
}();

cov_6nho2jgla.s[27]++;


angular.module('app').component('endpointCrudView', {
    templateUrl: 'app/components/endpointCrudView/endpointCrudView.html',
    controller: EventsListViewController,
    bindings: {
        items: '<',
        visibleFields: '<',
        filters: '<',
        displayName: '@',
        newItemRoute: '<'
    }
});