'use strict';

var cov_2a2hs8eg9y = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/routes.js',
        hash = '5e1ece34ec8e07cb33cd13aa092dfcfaa2705622',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 454,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 39,
                    column: 34
                },
                end: {
                    line: 39,
                    column: 46
                }
            },
            '5': {
                start: {
                    line: 47,
                    column: 38
                },
                end: {
                    line: 47,
                    column: 92
                }
            },
            '6': {
                start: {
                    line: 47,
                    column: 76
                },
                end: {
                    line: 47,
                    column: 82
                }
            },
            '7': {
                start: {
                    line: 49,
                    column: 20
                },
                end: {
                    line: 95,
                    column: 22
                }
            },
            '8': {
                start: {
                    line: 64,
                    column: 32
                },
                end: {
                    line: 75,
                    column: 33
                }
            },
            '9': {
                start: {
                    line: 66,
                    column: 36
                },
                end: {
                    line: 66,
                    column: 55
                }
            },
            '10': {
                start: {
                    line: 68,
                    column: 36
                },
                end: {
                    line: 68,
                    column: 54
                }
            },
            '11': {
                start: {
                    line: 70,
                    column: 36
                },
                end: {
                    line: 70,
                    column: 52
                }
            },
            '12': {
                start: {
                    line: 72,
                    column: 36
                },
                end: {
                    line: 72,
                    column: 55
                }
            },
            '13': {
                start: {
                    line: 74,
                    column: 36
                },
                end: {
                    line: 74,
                    column: 51
                }
            },
            '14': {
                start: {
                    line: 81,
                    column: 50
                },
                end: {
                    line: 81,
                    column: 85
                }
            },
            '15': {
                start: {
                    line: 87,
                    column: 50
                },
                end: {
                    line: 87,
                    column: 91
                }
            },
            '16': {
                start: {
                    line: 92,
                    column: 50
                },
                end: {
                    line: 92,
                    column: 86
                }
            },
            '17': {
                start: {
                    line: 98,
                    column: 20
                },
                end: {
                    line: 133,
                    column: 22
                }
            },
            '18': {
                start: {
                    line: 135,
                    column: 35
                },
                end: {
                    line: 135,
                    column: 44
                }
            },
            '19': {
                start: {
                    line: 136,
                    column: 36
                },
                end: {
                    line: 136,
                    column: 54
                }
            },
            '20': {
                start: {
                    line: 145,
                    column: 20
                },
                end: {
                    line: 147,
                    column: 21
                }
            },
            '21': {
                start: {
                    line: 146,
                    column: 24
                },
                end: {
                    line: 146,
                    column: 97
                }
            },
            '22': {
                start: {
                    line: 146,
                    column: 81
                },
                end: {
                    line: 146,
                    column: 86
                }
            },
            '23': {
                start: {
                    line: 148,
                    column: 20
                },
                end: {
                    line: 148,
                    column: 48
                }
            },
            '24': {
                start: {
                    line: 157,
                    column: 42
                },
                end: {
                    line: 157,
                    column: 127
                }
            },
            '25': {
                start: {
                    line: 157,
                    column: 107
                },
                end: {
                    line: 157,
                    column: 117
                }
            },
            '26': {
                start: {
                    line: 159,
                    column: 20
                },
                end: {
                    line: 186,
                    column: 22
                }
            },
            '27': {
                start: {
                    line: 173,
                    column: 50
                },
                end: {
                    line: 173,
                    column: 88
                }
            },
            '28': {
                start: {
                    line: 189,
                    column: 20
                },
                end: {
                    line: 212,
                    column: 22
                }
            },
            '29': {
                start: {
                    line: 214,
                    column: 35
                },
                end: {
                    line: 214,
                    column: 46
                }
            },
            '30': {
                start: {
                    line: 215,
                    column: 36
                },
                end: {
                    line: 215,
                    column: 58
                }
            },
            '31': {
                start: {
                    line: 224,
                    column: 20
                },
                end: {
                    line: 246,
                    column: 21
                }
            },
            '32': {
                start: {
                    line: 225,
                    column: 24
                },
                end: {
                    line: 225,
                    column: 101
                }
            },
            '33': {
                start: {
                    line: 225,
                    column: 85
                },
                end: {
                    line: 225,
                    column: 90
                }
            },
            '34': {
                start: {
                    line: 226,
                    column: 27
                },
                end: {
                    line: 246,
                    column: 21
                }
            },
            '35': {
                start: {
                    line: 227,
                    column: 24
                },
                end: {
                    line: 235,
                    column: 36
                }
            },
            '36': {
                start: {
                    line: 228,
                    column: 28
                },
                end: {
                    line: 234,
                    column: 31
                }
            },
            '37': {
                start: {
                    line: 236,
                    column: 27
                },
                end: {
                    line: 246,
                    column: 21
                }
            },
            '38': {
                start: {
                    line: 237,
                    column: 24
                },
                end: {
                    line: 245,
                    column: 36
                }
            },
            '39': {
                start: {
                    line: 238,
                    column: 28
                },
                end: {
                    line: 244,
                    column: 31
                }
            },
            '40': {
                start: {
                    line: 247,
                    column: 20
                },
                end: {
                    line: 247,
                    column: 52
                }
            },
            '41': {
                start: {
                    line: 256,
                    column: 37
                },
                end: {
                    line: 256,
                    column: 88
                }
            },
            '42': {
                start: {
                    line: 256,
                    column: 73
                },
                end: {
                    line: 256,
                    column: 78
                }
            },
            '43': {
                start: {
                    line: 258,
                    column: 20
                },
                end: {
                    line: 280,
                    column: 22
                }
            },
            '44': {
                start: {
                    line: 272,
                    column: 50
                },
                end: {
                    line: 272,
                    column: 96
                }
            },
            '45': {
                start: {
                    line: 277,
                    column: 50
                },
                end: {
                    line: 277,
                    column: 95
                }
            },
            '46': {
                start: {
                    line: 283,
                    column: 20
                },
                end: {
                    line: 305,
                    column: 22
                }
            },
            '47': {
                start: {
                    line: 307,
                    column: 35
                },
                end: {
                    line: 307,
                    column: 45
                }
            },
            '48': {
                start: {
                    line: 308,
                    column: 36
                },
                end: {
                    line: 308,
                    column: 53
                }
            },
            '49': {
                start: {
                    line: 316,
                    column: 37
                },
                end: {
                    line: 316,
                    column: 105
                }
            },
            '50': {
                start: {
                    line: 316,
                    column: 90
                },
                end: {
                    line: 316,
                    column: 95
                }
            },
            '51': {
                start: {
                    line: 318,
                    column: 20
                },
                end: {
                    line: 320,
                    column: 21
                }
            },
            '52': {
                start: {
                    line: 319,
                    column: 24
                },
                end: {
                    line: 319,
                    column: 94
                }
            },
            '53': {
                start: {
                    line: 319,
                    column: 79
                },
                end: {
                    line: 319,
                    column: 83
                }
            },
            '54': {
                start: {
                    line: 321,
                    column: 20
                },
                end: {
                    line: 321,
                    column: 47
                }
            },
            '55': {
                start: {
                    line: 330,
                    column: 37
                },
                end: {
                    line: 330,
                    column: 88
                }
            },
            '56': {
                start: {
                    line: 330,
                    column: 73
                },
                end: {
                    line: 330,
                    column: 78
                }
            },
            '57': {
                start: {
                    line: 332,
                    column: 20
                },
                end: {
                    line: 339,
                    column: 22
                }
            },
            '58': {
                start: {
                    line: 342,
                    column: 20
                },
                end: {
                    line: 347,
                    column: 22
                }
            },
            '59': {
                start: {
                    line: 349,
                    column: 35
                },
                end: {
                    line: 349,
                    column: 42
                }
            },
            '60': {
                start: {
                    line: 350,
                    column: 36
                },
                end: {
                    line: 350,
                    column: 53
                }
            },
            '61': {
                start: {
                    line: 359,
                    column: 20
                },
                end: {
                    line: 361,
                    column: 21
                }
            },
            '62': {
                start: {
                    line: 360,
                    column: 24
                },
                end: {
                    line: 360,
                    column: 94
                }
            },
            '63': {
                start: {
                    line: 360,
                    column: 79
                },
                end: {
                    line: 360,
                    column: 83
                }
            },
            '64': {
                start: {
                    line: 362,
                    column: 20
                },
                end: {
                    line: 362,
                    column: 47
                }
            },
            '65': {
                start: {
                    line: 370,
                    column: 47
                },
                end: {
                    line: 370,
                    column: 118
                }
            },
            '66': {
                start: {
                    line: 370,
                    column: 98
                },
                end: {
                    line: 370,
                    column: 108
                }
            },
            '67': {
                start: {
                    line: 372,
                    column: 20
                },
                end: {
                    line: 379,
                    column: 22
                }
            },
            '68': {
                start: {
                    line: 382,
                    column: 20
                },
                end: {
                    line: 387,
                    column: 22
                }
            },
            '69': {
                start: {
                    line: 389,
                    column: 35
                },
                end: {
                    line: 389,
                    column: 47
                }
            },
            '70': {
                start: {
                    line: 390,
                    column: 36
                },
                end: {
                    line: 390,
                    column: 62
                }
            },
            '71': {
                start: {
                    line: 399,
                    column: 20
                },
                end: {
                    line: 401,
                    column: 21
                }
            },
            '72': {
                start: {
                    line: 400,
                    column: 24
                },
                end: {
                    line: 400,
                    column: 112
                }
            },
            '73': {
                start: {
                    line: 400,
                    column: 93
                },
                end: {
                    line: 400,
                    column: 101
                }
            },
            '74': {
                start: {
                    line: 402,
                    column: 20
                },
                end: {
                    line: 402,
                    column: 57
                }
            },
            '75': {
                start: {
                    line: 410,
                    column: 41
                },
                end: {
                    line: 410,
                    column: 104
                }
            },
            '76': {
                start: {
                    line: 410,
                    column: 85
                },
                end: {
                    line: 410,
                    column: 94
                }
            },
            '77': {
                start: {
                    line: 412,
                    column: 20
                },
                end: {
                    line: 423,
                    column: 22
                }
            },
            '78': {
                start: {
                    line: 426,
                    column: 20
                },
                end: {
                    line: 431,
                    column: 22
                }
            },
            '79': {
                start: {
                    line: 433,
                    column: 35
                },
                end: {
                    line: 433,
                    column: 47
                }
            },
            '80': {
                start: {
                    line: 434,
                    column: 36
                },
                end: {
                    line: 434,
                    column: 57
                }
            },
            '81': {
                start: {
                    line: 448,
                    column: 20
                },
                end: {
                    line: 450,
                    column: 21
                }
            },
            '82': {
                start: {
                    line: 449,
                    column: 24
                },
                end: {
                    line: 449,
                    column: 106
                }
            },
            '83': {
                start: {
                    line: 449,
                    column: 87
                },
                end: {
                    line: 449,
                    column: 95
                }
            },
            '84': {
                start: {
                    line: 451,
                    column: 20
                },
                end: {
                    line: 451,
                    column: 51
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 455,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 39,
                        column: 28
                    },
                    end: {
                        line: 39,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 34
                    },
                    end: {
                        line: 39,
                        column: 46
                    }
                },
                line: 39
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 47,
                        column: 23
                    },
                    end: {
                        line: 47,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 38
                    },
                    end: {
                        line: 47,
                        column: 92
                    }
                },
                line: 47
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 47,
                        column: 66
                    },
                    end: {
                        line: 47,
                        column: 67
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 76
                    },
                    end: {
                        line: 47,
                        column: 82
                    }
                },
                line: 47
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 48,
                        column: 31
                    },
                    end: {
                        line: 48,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 37
                    },
                    end: {
                        line: 96,
                        column: 17
                    }
                },
                line: 48
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 63,
                        column: 41
                    },
                    end: {
                        line: 63,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 50
                    },
                    end: {
                        line: 76,
                        column: 29
                    }
                },
                line: 63
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 81,
                        column: 41
                    },
                    end: {
                        line: 81,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 50
                    },
                    end: {
                        line: 81,
                        column: 85
                    }
                },
                line: 81
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 87,
                        column: 41
                    },
                    end: {
                        line: 87,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 87,
                        column: 50
                    },
                    end: {
                        line: 87,
                        column: 91
                    }
                },
                line: 87
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 92,
                        column: 41
                    },
                    end: {
                        line: 92,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 50
                    },
                    end: {
                        line: 92,
                        column: 86
                    }
                },
                line: 92
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 97,
                        column: 25
                    },
                    end: {
                        line: 97,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 97,
                        column: 31
                    },
                    end: {
                        line: 134,
                        column: 17
                    }
                },
                line: 97
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 135,
                        column: 29
                    },
                    end: {
                        line: 135,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 135,
                        column: 35
                    },
                    end: {
                        line: 135,
                        column: 44
                    }
                },
                line: 135
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 136,
                        column: 30
                    },
                    end: {
                        line: 136,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 136,
                        column: 36
                    },
                    end: {
                        line: 136,
                        column: 54
                    }
                },
                line: 136
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 144,
                        column: 23
                    },
                    end: {
                        line: 144,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 54
                    },
                    end: {
                        line: 149,
                        column: 17
                    }
                },
                line: 144
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 146,
                        column: 72
                    },
                    end: {
                        line: 146,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 146,
                        column: 81
                    },
                    end: {
                        line: 146,
                        column: 86
                    }
                },
                line: 146
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 157,
                        column: 23
                    },
                    end: {
                        line: 157,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 42
                    },
                    end: {
                        line: 157,
                        column: 127
                    }
                },
                line: 157
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 157,
                        column: 93
                    },
                    end: {
                        line: 157,
                        column: 94
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 107
                    },
                    end: {
                        line: 157,
                        column: 117
                    }
                },
                line: 157
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 158,
                        column: 31
                    },
                    end: {
                        line: 158,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 158,
                        column: 37
                    },
                    end: {
                        line: 187,
                        column: 17
                    }
                },
                line: 158
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 173,
                        column: 41
                    },
                    end: {
                        line: 173,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 173,
                        column: 50
                    },
                    end: {
                        line: 173,
                        column: 88
                    }
                },
                line: 173
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 188,
                        column: 25
                    },
                    end: {
                        line: 188,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 188,
                        column: 31
                    },
                    end: {
                        line: 213,
                        column: 17
                    }
                },
                line: 188
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 214,
                        column: 29
                    },
                    end: {
                        line: 214,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 214,
                        column: 35
                    },
                    end: {
                        line: 214,
                        column: 46
                    }
                },
                line: 214
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 215,
                        column: 30
                    },
                    end: {
                        line: 215,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 215,
                        column: 36
                    },
                    end: {
                        line: 215,
                        column: 58
                    }
                },
                line: 215
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 223,
                        column: 27
                    },
                    end: {
                        line: 223,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 223,
                        column: 100
                    },
                    end: {
                        line: 248,
                        column: 17
                    }
                },
                line: 223
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 225,
                        column: 76
                    },
                    end: {
                        line: 225,
                        column: 77
                    }
                },
                loc: {
                    start: {
                        line: 225,
                        column: 85
                    },
                    end: {
                        line: 225,
                        column: 90
                    }
                },
                line: 225
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 227,
                        column: 75
                    },
                    end: {
                        line: 227,
                        column: 76
                    }
                },
                loc: {
                    start: {
                        line: 227,
                        column: 84
                    },
                    end: {
                        line: 235,
                        column: 25
                    }
                },
                line: 227
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 237,
                        column: 73
                    },
                    end: {
                        line: 237,
                        column: 74
                    }
                },
                loc: {
                    start: {
                        line: 237,
                        column: 81
                    },
                    end: {
                        line: 245,
                        column: 25
                    }
                },
                line: 237
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 256,
                        column: 23
                    },
                    end: {
                        line: 256,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 256,
                        column: 37
                    },
                    end: {
                        line: 256,
                        column: 88
                    }
                },
                line: 256
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 256,
                        column: 64
                    },
                    end: {
                        line: 256,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 256,
                        column: 73
                    },
                    end: {
                        line: 256,
                        column: 78
                    }
                },
                line: 256
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 257,
                        column: 31
                    },
                    end: {
                        line: 257,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 257,
                        column: 37
                    },
                    end: {
                        line: 281,
                        column: 17
                    }
                },
                line: 257
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 272,
                        column: 41
                    },
                    end: {
                        line: 272,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 272,
                        column: 50
                    },
                    end: {
                        line: 272,
                        column: 96
                    }
                },
                line: 272
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 277,
                        column: 41
                    },
                    end: {
                        line: 277,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 277,
                        column: 50
                    },
                    end: {
                        line: 277,
                        column: 95
                    }
                },
                line: 277
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 282,
                        column: 25
                    },
                    end: {
                        line: 282,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 282,
                        column: 31
                    },
                    end: {
                        line: 306,
                        column: 17
                    }
                },
                line: 282
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 307,
                        column: 29
                    },
                    end: {
                        line: 307,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 307,
                        column: 35
                    },
                    end: {
                        line: 307,
                        column: 45
                    }
                },
                line: 307
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 308,
                        column: 30
                    },
                    end: {
                        line: 308,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 308,
                        column: 36
                    },
                    end: {
                        line: 308,
                        column: 53
                    }
                },
                line: 308
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 316,
                        column: 23
                    },
                    end: {
                        line: 316,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 316,
                        column: 37
                    },
                    end: {
                        line: 316,
                        column: 105
                    }
                },
                line: 316
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 316,
                        column: 81
                    },
                    end: {
                        line: 316,
                        column: 82
                    }
                },
                loc: {
                    start: {
                        line: 316,
                        column: 90
                    },
                    end: {
                        line: 316,
                        column: 95
                    }
                },
                line: 316
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 317,
                        column: 22
                    },
                    end: {
                        line: 317,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 317,
                        column: 52
                    },
                    end: {
                        line: 322,
                        column: 17
                    }
                },
                line: 317
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 319,
                        column: 71
                    },
                    end: {
                        line: 319,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 319,
                        column: 79
                    },
                    end: {
                        line: 319,
                        column: 83
                    }
                },
                line: 319
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 330,
                        column: 23
                    },
                    end: {
                        line: 330,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 330,
                        column: 37
                    },
                    end: {
                        line: 330,
                        column: 88
                    }
                },
                line: 330
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 330,
                        column: 64
                    },
                    end: {
                        line: 330,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 330,
                        column: 73
                    },
                    end: {
                        line: 330,
                        column: 78
                    }
                },
                line: 330
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 331,
                        column: 31
                    },
                    end: {
                        line: 331,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 331,
                        column: 37
                    },
                    end: {
                        line: 340,
                        column: 17
                    }
                },
                line: 331
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 341,
                        column: 25
                    },
                    end: {
                        line: 341,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 341,
                        column: 31
                    },
                    end: {
                        line: 348,
                        column: 17
                    }
                },
                line: 341
            },
            '41': {
                name: '(anonymous_41)',
                decl: {
                    start: {
                        line: 349,
                        column: 29
                    },
                    end: {
                        line: 349,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 349,
                        column: 35
                    },
                    end: {
                        line: 349,
                        column: 42
                    }
                },
                line: 349
            },
            '42': {
                name: '(anonymous_42)',
                decl: {
                    start: {
                        line: 350,
                        column: 30
                    },
                    end: {
                        line: 350,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 350,
                        column: 36
                    },
                    end: {
                        line: 350,
                        column: 53
                    }
                },
                line: 350
            },
            '43': {
                name: '(anonymous_43)',
                decl: {
                    start: {
                        line: 358,
                        column: 22
                    },
                    end: {
                        line: 358,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 358,
                        column: 52
                    },
                    end: {
                        line: 363,
                        column: 17
                    }
                },
                line: 358
            },
            '44': {
                name: '(anonymous_44)',
                decl: {
                    start: {
                        line: 360,
                        column: 71
                    },
                    end: {
                        line: 360,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 360,
                        column: 79
                    },
                    end: {
                        line: 360,
                        column: 83
                    }
                },
                line: 360
            },
            '45': {
                name: '(anonymous_45)',
                decl: {
                    start: {
                        line: 370,
                        column: 23
                    },
                    end: {
                        line: 370,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 370,
                        column: 47
                    },
                    end: {
                        line: 370,
                        column: 118
                    }
                },
                line: 370
            },
            '46': {
                name: '(anonymous_46)',
                decl: {
                    start: {
                        line: 370,
                        column: 84
                    },
                    end: {
                        line: 370,
                        column: 85
                    }
                },
                loc: {
                    start: {
                        line: 370,
                        column: 98
                    },
                    end: {
                        line: 370,
                        column: 108
                    }
                },
                line: 370
            },
            '47': {
                name: '(anonymous_47)',
                decl: {
                    start: {
                        line: 371,
                        column: 31
                    },
                    end: {
                        line: 371,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 371,
                        column: 37
                    },
                    end: {
                        line: 380,
                        column: 17
                    }
                },
                line: 371
            },
            '48': {
                name: '(anonymous_48)',
                decl: {
                    start: {
                        line: 381,
                        column: 25
                    },
                    end: {
                        line: 381,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 381,
                        column: 31
                    },
                    end: {
                        line: 388,
                        column: 17
                    }
                },
                line: 381
            },
            '49': {
                name: '(anonymous_49)',
                decl: {
                    start: {
                        line: 389,
                        column: 29
                    },
                    end: {
                        line: 389,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 389,
                        column: 35
                    },
                    end: {
                        line: 389,
                        column: 47
                    }
                },
                line: 389
            },
            '50': {
                name: '(anonymous_50)',
                decl: {
                    start: {
                        line: 390,
                        column: 30
                    },
                    end: {
                        line: 390,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 390,
                        column: 36
                    },
                    end: {
                        line: 390,
                        column: 62
                    }
                },
                line: 390
            },
            '51': {
                name: '(anonymous_51)',
                decl: {
                    start: {
                        line: 398,
                        column: 31
                    },
                    end: {
                        line: 398,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 398,
                        column: 71
                    },
                    end: {
                        line: 403,
                        column: 17
                    }
                },
                line: 398
            },
            '52': {
                name: '(anonymous_52)',
                decl: {
                    start: {
                        line: 400,
                        column: 81
                    },
                    end: {
                        line: 400,
                        column: 82
                    }
                },
                loc: {
                    start: {
                        line: 400,
                        column: 93
                    },
                    end: {
                        line: 400,
                        column: 101
                    }
                },
                line: 400
            },
            '53': {
                name: '(anonymous_53)',
                decl: {
                    start: {
                        line: 410,
                        column: 23
                    },
                    end: {
                        line: 410,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 410,
                        column: 41
                    },
                    end: {
                        line: 410,
                        column: 104
                    }
                },
                line: 410
            },
            '54': {
                name: '(anonymous_54)',
                decl: {
                    start: {
                        line: 410,
                        column: 72
                    },
                    end: {
                        line: 410,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 410,
                        column: 85
                    },
                    end: {
                        line: 410,
                        column: 94
                    }
                },
                line: 410
            },
            '55': {
                name: '(anonymous_55)',
                decl: {
                    start: {
                        line: 411,
                        column: 31
                    },
                    end: {
                        line: 411,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 411,
                        column: 37
                    },
                    end: {
                        line: 424,
                        column: 17
                    }
                },
                line: 411
            },
            '56': {
                name: '(anonymous_56)',
                decl: {
                    start: {
                        line: 425,
                        column: 25
                    },
                    end: {
                        line: 425,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 425,
                        column: 31
                    },
                    end: {
                        line: 432,
                        column: 17
                    }
                },
                line: 425
            },
            '57': {
                name: '(anonymous_57)',
                decl: {
                    start: {
                        line: 433,
                        column: 29
                    },
                    end: {
                        line: 433,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 433,
                        column: 35
                    },
                    end: {
                        line: 433,
                        column: 47
                    }
                },
                line: 433
            },
            '58': {
                name: '(anonymous_58)',
                decl: {
                    start: {
                        line: 434,
                        column: 30
                    },
                    end: {
                        line: 434,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 434,
                        column: 36
                    },
                    end: {
                        line: 434,
                        column: 57
                    }
                },
                line: 434
            },
            '59': {
                name: '(anonymous_59)',
                decl: {
                    start: {
                        line: 447,
                        column: 26
                    },
                    end: {
                        line: 447,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 447,
                        column: 60
                    },
                    end: {
                        line: 452,
                        column: 17
                    }
                },
                line: 447
            },
            '60': {
                name: '(anonymous_60)',
                decl: {
                    start: {
                        line: 449,
                        column: 75
                    },
                    end: {
                        line: 449,
                        column: 76
                    }
                },
                loc: {
                    start: {
                        line: 449,
                        column: 87
                    },
                    end: {
                        line: 449,
                        column: 95
                    }
                },
                line: 449
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 64,
                        column: 32
                    },
                    end: {
                        line: 75,
                        column: 33
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 65,
                        column: 32
                    },
                    end: {
                        line: 66,
                        column: 55
                    }
                }, {
                    start: {
                        line: 67,
                        column: 32
                    },
                    end: {
                        line: 68,
                        column: 54
                    }
                }, {
                    start: {
                        line: 69,
                        column: 32
                    },
                    end: {
                        line: 70,
                        column: 52
                    }
                }, {
                    start: {
                        line: 71,
                        column: 32
                    },
                    end: {
                        line: 72,
                        column: 55
                    }
                }, {
                    start: {
                        line: 73,
                        column: 32
                    },
                    end: {
                        line: 74,
                        column: 51
                    }
                }],
                line: 64
            },
            '1': {
                loc: {
                    start: {
                        line: 81,
                        column: 50
                    },
                    end: {
                        line: 81,
                        column: 85
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 81,
                        column: 69
                    },
                    end: {
                        line: 81,
                        column: 80
                    }
                }, {
                    start: {
                        line: 81,
                        column: 83
                    },
                    end: {
                        line: 81,
                        column: 85
                    }
                }],
                line: 81
            },
            '2': {
                loc: {
                    start: {
                        line: 87,
                        column: 50
                    },
                    end: {
                        line: 87,
                        column: 91
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 87,
                        column: 69
                    },
                    end: {
                        line: 87,
                        column: 78
                    }
                }, {
                    start: {
                        line: 87,
                        column: 81
                    },
                    end: {
                        line: 87,
                        column: 91
                    }
                }],
                line: 87
            },
            '3': {
                loc: {
                    start: {
                        line: 92,
                        column: 50
                    },
                    end: {
                        line: 92,
                        column: 86
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 92,
                        column: 69
                    },
                    end: {
                        line: 92,
                        column: 80
                    }
                }, {
                    start: {
                        line: 92,
                        column: 83
                    },
                    end: {
                        line: 92,
                        column: 86
                    }
                }],
                line: 92
            },
            '4': {
                loc: {
                    start: {
                        line: 145,
                        column: 20
                    },
                    end: {
                        line: 147,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 145,
                        column: 20
                    },
                    end: {
                        line: 147,
                        column: 21
                    }
                }, {
                    start: {
                        line: 145,
                        column: 20
                    },
                    end: {
                        line: 147,
                        column: 21
                    }
                }],
                line: 145
            },
            '5': {
                loc: {
                    start: {
                        line: 173,
                        column: 50
                    },
                    end: {
                        line: 173,
                        column: 88
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 173,
                        column: 69
                    },
                    end: {
                        line: 173,
                        column: 76
                    }
                }, {
                    start: {
                        line: 173,
                        column: 79
                    },
                    end: {
                        line: 173,
                        column: 88
                    }
                }],
                line: 173
            },
            '6': {
                loc: {
                    start: {
                        line: 224,
                        column: 20
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 224,
                        column: 20
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }, {
                    start: {
                        line: 224,
                        column: 20
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }],
                line: 224
            },
            '7': {
                loc: {
                    start: {
                        line: 226,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 226,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }, {
                    start: {
                        line: 226,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }],
                line: 226
            },
            '8': {
                loc: {
                    start: {
                        line: 236,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 236,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }, {
                    start: {
                        line: 236,
                        column: 27
                    },
                    end: {
                        line: 246,
                        column: 21
                    }
                }],
                line: 236
            },
            '9': {
                loc: {
                    start: {
                        line: 272,
                        column: 50
                    },
                    end: {
                        line: 272,
                        column: 96
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 272,
                        column: 72
                    },
                    end: {
                        line: 272,
                        column: 82
                    }
                }, {
                    start: {
                        line: 272,
                        column: 85
                    },
                    end: {
                        line: 272,
                        column: 96
                    }
                }],
                line: 272
            },
            '10': {
                loc: {
                    start: {
                        line: 277,
                        column: 50
                    },
                    end: {
                        line: 277,
                        column: 95
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 277,
                        column: 71
                    },
                    end: {
                        line: 277,
                        column: 80
                    }
                }, {
                    start: {
                        line: 277,
                        column: 83
                    },
                    end: {
                        line: 277,
                        column: 95
                    }
                }],
                line: 277
            },
            '11': {
                loc: {
                    start: {
                        line: 318,
                        column: 20
                    },
                    end: {
                        line: 320,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 318,
                        column: 20
                    },
                    end: {
                        line: 320,
                        column: 21
                    }
                }, {
                    start: {
                        line: 318,
                        column: 20
                    },
                    end: {
                        line: 320,
                        column: 21
                    }
                }],
                line: 318
            },
            '12': {
                loc: {
                    start: {
                        line: 359,
                        column: 20
                    },
                    end: {
                        line: 361,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 359,
                        column: 20
                    },
                    end: {
                        line: 361,
                        column: 21
                    }
                }, {
                    start: {
                        line: 359,
                        column: 20
                    },
                    end: {
                        line: 361,
                        column: 21
                    }
                }],
                line: 359
            },
            '13': {
                loc: {
                    start: {
                        line: 399,
                        column: 20
                    },
                    end: {
                        line: 401,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 399,
                        column: 20
                    },
                    end: {
                        line: 401,
                        column: 21
                    }
                }, {
                    start: {
                        line: 399,
                        column: 20
                    },
                    end: {
                        line: 401,
                        column: 21
                    }
                }],
                line: 399
            },
            '14': {
                loc: {
                    start: {
                        line: 448,
                        column: 20
                    },
                    end: {
                        line: 450,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 448,
                        column: 20
                    },
                    end: {
                        line: 450,
                        column: 21
                    }
                }, {
                    start: {
                        line: 448,
                        column: 20
                    },
                    end: {
                        line: 450,
                        column: 21
                    }
                }],
                line: 448
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0
        },
        b: {
            '0': [0, 0, 0, 0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2a2hs8eg9y.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_2a2hs8eg9y.f[0]++;
    cov_2a2hs8eg9y.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_2a2hs8eg9y.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_2a2hs8eg9y.s[3]++;
    $stateProvider.state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('forgot', {
        url: '/recuperar_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/reset_password?token=&email=',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('home', {
        url: '/',
        template: '<home-view welcome-msg="$resolve.welcomeMsg" flex layout="column"/>',
        resolve: {
            welcomeMsg: function welcomeMsg() {
                cov_2a2hs8eg9y.f[1]++;
                cov_2a2hs8eg9y.s[4]++;
                return 'Hello Solo';
            }
        },
        requireLogin: true
    }).state('eventsListView', {
        url: '/events',
        component: 'endpointCrudView',
        resolve: {
            items: function items(EventsTable) {
                cov_2a2hs8eg9y.f[2]++;
                cov_2a2hs8eg9y.s[5]++;
                return EventsTable.$search().$then(function (events) {
                    cov_2a2hs8eg9y.f[3]++;
                    cov_2a2hs8eg9y.s[6]++;
                    return events;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[4]++;
                cov_2a2hs8eg9y.s[7]++;

                return {
                    name: {
                        displayName: 'Titulo',
                        displayType: 'ROUTER',
                        routeName: 'eventDetailsView',
                        sortable: true
                    },
                    startDate: {
                        displayName: 'Data',
                        displayType: 'CALENDAR',
                        sortable: true
                    },
                    state: {
                        displayName: 'Estado',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[5]++;
                            cov_2a2hs8eg9y.s[8]++;

                            switch (value) {
                                case 'ARCHIVED':
                                    cov_2a2hs8eg9y.b[0][0]++;
                                    cov_2a2hs8eg9y.s[9]++;

                                    return 'Arquivado';
                                case 'SOLDOUT':
                                    cov_2a2hs8eg9y.b[0][1]++;
                                    cov_2a2hs8eg9y.s[10]++;

                                    return 'Esgotado';
                                case 'POSTPONED':
                                    cov_2a2hs8eg9y.b[0][2]++;
                                    cov_2a2hs8eg9y.s[11]++;

                                    return 'Adiado';
                                case 'CANCELED':
                                    cov_2a2hs8eg9y.b[0][3]++;
                                    cov_2a2hs8eg9y.s[12]++;

                                    return 'Cancelado';
                                default:
                                    cov_2a2hs8eg9y.b[0][4]++;
                                    cov_2a2hs8eg9y.s[13]++;

                                    return 'Ativo';
                            }
                        },
                        sortable: true
                    },
                    isArchived: {
                        displayName: 'Arquivado',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[6]++;
                            cov_2a2hs8eg9y.s[14]++;
                            return value === true ? (cov_2a2hs8eg9y.b[1][0]++, 'Arquivado') : (cov_2a2hs8eg9y.b[1][1]++, '');
                        },
                        sortable: true
                    },

                    isVisible: {
                        displayName: 'Visibilidade',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[7]++;
                            cov_2a2hs8eg9y.s[15]++;
                            return value === true ? (cov_2a2hs8eg9y.b[2][0]++, 'Visível') : (cov_2a2hs8eg9y.b[2][1]++, 'Rascunho');
                        },
                        sortable: true
                    },
                    isHighlighted: {
                        displayName: 'Em destaque',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[8]++;
                            cov_2a2hs8eg9y.s[16]++;
                            return value === true ? (cov_2a2hs8eg9y.b[3][0]++, 'Destacado') : (cov_2a2hs8eg9y.b[3][1]++, ' ');
                        },
                        sortable: true
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[9]++;
                cov_2a2hs8eg9y.s[17]++;

                return {
                    /* eslint-disable camelcase */
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por nome'
                    },
                    state: {
                        type: 'SELECT',
                        displayName: 'Filtrar p/ estado',
                        /* eslint-disable quote-props */
                        options: {
                            'POSTPONED': 'Adiado',
                            'ACTIVE': 'Ativo',
                            'CANCELED': 'Cancelado',
                            'SOLDOUT': 'Esgotado'
                        }
                    },
                    is_archived: {
                        type: 'SELECT',
                        displayName: 'Filtrar p/ arquivado',
                        /* eslint-disable quote-props */
                        options: {
                            '0': 'Não Arquivado',
                            '1': 'Arquivado'
                        }
                    },
                    is_visible: {
                        type: 'SELECT',
                        displayName: 'Filtrar p/ visibilidade',
                        /* eslint-disable quote-props */
                        options: {
                            '0': 'Rascunho',
                            '1': 'Visível'
                        }
                    }
                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[10]++;
                cov_2a2hs8eg9y.s[18]++;
                return 'Eventos';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[11]++;
                cov_2a2hs8eg9y.s[19]++;
                return 'eventDetailsView';
            }
        },
        requireLogin: true
    }).state('eventDetailsView', {
        url: '/event/:id/',
        component: 'eventDetailsView',
        resolve: {
            event: function event($stateParams, EventsTable) {
                cov_2a2hs8eg9y.f[12]++;
                cov_2a2hs8eg9y.s[20]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[4][0]++;
                    cov_2a2hs8eg9y.s[21]++;

                    return EventsTable.$find($stateParams.id).$then(function (event) {
                        cov_2a2hs8eg9y.f[13]++;
                        cov_2a2hs8eg9y.s[22]++;
                        return event;
                    }).$promise;
                } else {
                    cov_2a2hs8eg9y.b[4][1]++;
                }
                cov_2a2hs8eg9y.s[23]++;
                return EventsTable.$build();
            }
        },
        requireLogin: true
    }).state('highlightsListView', {
        url: '/destaques',
        component: 'endpointCrudView',
        resolve: {
            items: function items(HighlightsTable) {
                cov_2a2hs8eg9y.f[14]++;
                cov_2a2hs8eg9y.s[24]++;
                return HighlightsTable.$search({ ordering: 'order' }).$then(function (highlights) {
                    cov_2a2hs8eg9y.f[15]++;
                    cov_2a2hs8eg9y.s[25]++;
                    return highlights;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[16]++;
                cov_2a2hs8eg9y.s[26]++;

                return {
                    name: {
                        displayName: 'Titulo',
                        displayType: 'ROUTER',
                        routeName: 'highlightDetailsView',
                        sortable: true
                    },
                    type: {
                        displayName: 'Tipo',
                        displayType: 'TEXT',
                        sortable: true
                    },
                    isActive: {
                        displayName: 'Ativo',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[17]++;
                            cov_2a2hs8eg9y.s[27]++;
                            return value === true ? (cov_2a2hs8eg9y.b[5][0]++, 'Ativo') : (cov_2a2hs8eg9y.b[5][1]++, 'Inativo');
                        },
                        sortable: false
                    },
                    order: {
                        displayName: 'Posição',
                        displayType: 'TEXT',
                        sortable: true
                    },
                    endDate: {
                        displayName: 'Data Fim',
                        displayType: 'CALENDAR',
                        sortable: true
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[18]++;
                cov_2a2hs8eg9y.s[28]++;

                return {
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por nome'
                    },
                    is_active: {
                        type: 'SELECT',
                        displayName: 'Filtrar Estado',
                        options: {
                            '0': 'Inativo',
                            '1': 'Ativo'
                        }
                    },
                    type: {
                        type: 'SELECT',
                        displayName: 'Filtrar Tipo',
                        options: {
                            EVENT: 'Evento',
                            NEWS: 'Notícia',
                            LINK: 'Link',
                            PASTIME: 'Passatempo'
                        }
                    }
                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[19]++;
                cov_2a2hs8eg9y.s[29]++;
                return 'Destaques';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[20]++;
                cov_2a2hs8eg9y.s[30]++;
                return 'highlightDetailsView';
            }
        },
        requireLogin: true
    }).state('highlightDetailsView', {
        url: '/destaque/:id/?event=&post=&type=',
        component: 'highlightDetailsView',
        resolve: {
            highlight: function highlight($stateParams, HighlightsTable, EventsTable, PostsTable, AppSettings) {
                cov_2a2hs8eg9y.f[21]++;
                cov_2a2hs8eg9y.s[31]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[6][0]++;
                    cov_2a2hs8eg9y.s[32]++;

                    return HighlightsTable.$find($stateParams.id).$then(function (event) {
                        cov_2a2hs8eg9y.f[22]++;
                        cov_2a2hs8eg9y.s[33]++;
                        return event;
                    }).$promise;
                } else {
                        cov_2a2hs8eg9y.b[6][1]++;
                        cov_2a2hs8eg9y.s[34]++;
                        if ($stateParams.event) {
                            cov_2a2hs8eg9y.b[7][0]++;
                            cov_2a2hs8eg9y.s[35]++;

                            return EventsTable.$find($stateParams.event).$then(function (event) {
                                cov_2a2hs8eg9y.f[23]++;
                                cov_2a2hs8eg9y.s[36]++;

                                return HighlightsTable.$build({
                                    name: event.name,
                                    url: AppSettings.siteUrl + '/evento/' + event.slug + '/',
                                    event: event.id,
                                    image: event.poster,
                                    type: $stateParams.type
                                });
                            }).$promise;
                        } else {
                                cov_2a2hs8eg9y.b[7][1]++;
                                cov_2a2hs8eg9y.s[37]++;
                                if ($stateParams.post) {
                                    cov_2a2hs8eg9y.b[8][0]++;
                                    cov_2a2hs8eg9y.s[38]++;

                                    return PostsTable.$find($stateParams.post).$then(function (post) {
                                        cov_2a2hs8eg9y.f[24]++;
                                        cov_2a2hs8eg9y.s[39]++;

                                        return HighlightsTable.$build({
                                            name: post.name,
                                            url: AppSettings.siteUrl + '/noticia/' + post.slug + '/',
                                            post: post.id,
                                            image: post.cover,
                                            type: $stateParams.type
                                        });
                                    }).$promise;
                                } else {
                                    cov_2a2hs8eg9y.b[8][1]++;
                                }
                            }
                    }cov_2a2hs8eg9y.s[40]++;
                return HighlightsTable.$build();
            }
        }
    }).state('postsListView', {
        url: '/posts',
        component: 'endpointCrudView',
        resolve: {
            items: function items(PostsTable) {
                cov_2a2hs8eg9y.f[25]++;
                cov_2a2hs8eg9y.s[41]++;
                return PostsTable.$search().$then(function (posts) {
                    cov_2a2hs8eg9y.f[26]++;
                    cov_2a2hs8eg9y.s[42]++;
                    return posts;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[27]++;
                cov_2a2hs8eg9y.s[43]++;

                return {
                    name: {
                        displayName: 'Título',
                        displayType: 'ROUTER',
                        routeName: 'postDetailsView',
                        sortable: true
                    },
                    publishedAt: {
                        displayName: 'Data Publicação',
                        displayType: 'CALENDAR',
                        sortable: false
                    },
                    state: {
                        displayName: 'Estado',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[28]++;
                            cov_2a2hs8eg9y.s[44]++;
                            return value === 'DRAFT' ? (cov_2a2hs8eg9y.b[9][0]++, 'Rascunho') : (cov_2a2hs8eg9y.b[9][1]++, 'Publicada');
                        },
                        sortable: false
                    },
                    type: {
                        displayName: 'Tipo',
                        displayType: function displayType(value) {
                            cov_2a2hs8eg9y.f[29]++;
                            cov_2a2hs8eg9y.s[45]++;
                            return value === 'NEWS' ? (cov_2a2hs8eg9y.b[10][0]++, 'Notícia') : (cov_2a2hs8eg9y.b[10][1]++, 'Passatempo');
                        },
                        sortable: false
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[30]++;
                cov_2a2hs8eg9y.s[46]++;

                return {
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por título'
                    },
                    state: {
                        type: 'SELECT',
                        displayName: 'Filtrar p/ estado',
                        options: {
                            'DRAFT': 'Rascunho',
                            'PUBLISHED': 'Publicada'
                        }
                    },
                    type: {
                        type: 'SELECT',
                        displayName: 'Filtrar p/ tipo conteúdo',
                        options: {
                            'NEWS': 'Notícia',
                            'PASTIME': 'Passatempo'
                        }
                    }

                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[31]++;
                cov_2a2hs8eg9y.s[47]++;
                return 'Notícias';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[32]++;
                cov_2a2hs8eg9y.s[48]++;
                return 'postDetailsView';
            }
        },
        requireLogin: true
    }).state('postDetailsView', {
        url: '/post/:id/',
        component: 'postDetailsView',
        resolve: {
            posts: function posts(PostsTable) {
                cov_2a2hs8eg9y.f[33]++;
                cov_2a2hs8eg9y.s[49]++;
                return PostsTable.$search({ page_size: 9999 }).$then(function (posts) {
                    cov_2a2hs8eg9y.f[34]++;
                    cov_2a2hs8eg9y.s[50]++;
                    return posts;
                }).$promise;
            },
            post: function post($stateParams, PostsTable) {
                cov_2a2hs8eg9y.f[35]++;
                cov_2a2hs8eg9y.s[51]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[11][0]++;
                    cov_2a2hs8eg9y.s[52]++;

                    return PostsTable.$find($stateParams.id).$then(function (post) {
                        cov_2a2hs8eg9y.f[36]++;
                        cov_2a2hs8eg9y.s[53]++;
                        return post;
                    }).$promise;
                } else {
                    cov_2a2hs8eg9y.b[11][1]++;
                }
                cov_2a2hs8eg9y.s[54]++;
                return PostsTable.$build();
            }
        },
        requireLogin: true
    }).state('roomsListView', {
        url: '/rooms',
        component: 'endpointCrudView',
        resolve: {
            items: function items(RoomsTable) {
                cov_2a2hs8eg9y.f[37]++;
                cov_2a2hs8eg9y.s[55]++;
                return RoomsTable.$search().$then(function (rooms) {
                    cov_2a2hs8eg9y.f[38]++;
                    cov_2a2hs8eg9y.s[56]++;
                    return rooms;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[39]++;
                cov_2a2hs8eg9y.s[57]++;

                return {
                    name: {
                        displayName: 'Nome da sala',
                        displayType: 'ROUTER',
                        routeName: 'roomDetailsView',
                        sortable: true
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[40]++;
                cov_2a2hs8eg9y.s[58]++;

                return {
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por nome'
                    }
                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[41]++;
                cov_2a2hs8eg9y.s[59]++;
                return 'Salas';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[42]++;
                cov_2a2hs8eg9y.s[60]++;
                return 'roomDetailsView';
            }
        },
        requireLogin: true
    }).state('roomDetailsView', {
        url: '/room/:id/',
        component: 'roomDetailsView',
        resolve: {
            room: function room($stateParams, RoomsTable) {
                cov_2a2hs8eg9y.f[43]++;
                cov_2a2hs8eg9y.s[61]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[12][0]++;
                    cov_2a2hs8eg9y.s[62]++;

                    return RoomsTable.$find($stateParams.id).$then(function (room) {
                        cov_2a2hs8eg9y.f[44]++;
                        cov_2a2hs8eg9y.s[63]++;
                        return room;
                    }).$promise;
                } else {
                    cov_2a2hs8eg9y.b[12][1]++;
                }
                cov_2a2hs8eg9y.s[64]++;
                return RoomsTable.$build();
            }
        }
    }).state('eventCategoriesListView', {
        url: '/event-categories',
        component: 'endpointCrudView',
        resolve: {
            items: function items(EventCategoriesTable) {
                cov_2a2hs8eg9y.f[45]++;
                cov_2a2hs8eg9y.s[65]++;
                return EventCategoriesTable.$search().$then(function (categories) {
                    cov_2a2hs8eg9y.f[46]++;
                    cov_2a2hs8eg9y.s[66]++;
                    return categories;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[47]++;
                cov_2a2hs8eg9y.s[67]++;

                return {
                    name: {
                        displayName: 'Categoria',
                        displayType: 'ROUTER',
                        routeName: 'eventCategoryDetailsView',
                        sortable: true
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[48]++;
                cov_2a2hs8eg9y.s[68]++;

                return {
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por nome'
                    }
                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[49]++;
                cov_2a2hs8eg9y.s[69]++;
                return 'Categorias';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[50]++;
                cov_2a2hs8eg9y.s[70]++;
                return 'eventCategoryDetailsView';
            }
        },
        requireLogin: true
    }).state('eventCategoryDetailsView', {
        url: '/event-category/:id/',
        component: 'eventCategoryDetailsView',
        resolve: {
            eventCategory: function eventCategory($stateParams, EventCategoriesTable) {
                cov_2a2hs8eg9y.f[51]++;
                cov_2a2hs8eg9y.s[71]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[13][0]++;
                    cov_2a2hs8eg9y.s[72]++;

                    return EventCategoriesTable.$find($stateParams.id).$then(function (category) {
                        cov_2a2hs8eg9y.f[52]++;
                        cov_2a2hs8eg9y.s[73]++;
                        return category;
                    }).$promise;
                } else {
                    cov_2a2hs8eg9y.b[13][1]++;
                }
                cov_2a2hs8eg9y.s[74]++;
                return EventCategoriesTable.$build();
            }
        }
    }).state('promotersListView', {
        url: '/promoters',
        component: 'endpointCrudView',
        resolve: {
            items: function items(PromotersTable) {
                cov_2a2hs8eg9y.f[53]++;
                cov_2a2hs8eg9y.s[75]++;
                return PromotersTable.$search().$then(function (promoters) {
                    cov_2a2hs8eg9y.f[54]++;
                    cov_2a2hs8eg9y.s[76]++;
                    return promoters;
                }).$promise;
            },
            visibleFields: function visibleFields() {
                cov_2a2hs8eg9y.f[55]++;
                cov_2a2hs8eg9y.s[77]++;

                return {
                    name: {
                        displayName: 'Promotor',
                        displayType: 'ROUTER',
                        routeName: 'promoterDetailsView',
                        sortable: true
                    },
                    url: {
                        displayName: 'Link do promotor',
                        sortable: true
                    }
                };
            },
            filters: function filters() {
                cov_2a2hs8eg9y.f[56]++;
                cov_2a2hs8eg9y.s[78]++;

                return {
                    name__icontains: {
                        type: 'TEXT',
                        displayName: 'Pesquisar por nome'
                    }
                };
            },
            displayName: function displayName() {
                cov_2a2hs8eg9y.f[57]++;
                cov_2a2hs8eg9y.s[79]++;
                return 'Promotores';
            },
            newItemRoute: function newItemRoute() {
                cov_2a2hs8eg9y.f[58]++;
                cov_2a2hs8eg9y.s[80]++;
                return 'promoterDetailsView';
            }
        },
        requireLogin: true
    }).state('signatureDetailsView', {
        url: '/signature',
        component: 'signatureDetailsView',
        requireLogin: true
    }).state('promoterDetailsView', {
        url: '/promoter/:id/',
        component: 'promoterDetailsView',
        resolve: {
            promoter: function promoter($stateParams, PromotersTable) {
                cov_2a2hs8eg9y.f[59]++;
                cov_2a2hs8eg9y.s[81]++;

                if ($stateParams.id) {
                    cov_2a2hs8eg9y.b[14][0]++;
                    cov_2a2hs8eg9y.s[82]++;

                    return PromotersTable.$find($stateParams.id).$then(function (promoter) {
                        cov_2a2hs8eg9y.f[60]++;
                        cov_2a2hs8eg9y.s[83]++;
                        return promoter;
                    }).$promise;
                } else {
                    cov_2a2hs8eg9y.b[14][1]++;
                }
                cov_2a2hs8eg9y.s[84]++;
                return PromotersTable.$build();
            }
        }
    });
}