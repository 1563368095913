"use strict";

var cov_1ofpaxtmw1 = function () {
    var path = "/home/lobo/repos/coliseu-admin/src/app/components/eventDetailsView/eventDetailsView.js",
        hash = "b6d7555d6738a2260731cbf3a2d6580a1352d337",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/coliseu-admin/src/app/components/eventDetailsView/eventDetailsView.js",
        statementMap: {
            "0": {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 39
                }
            },
            "1": {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 59
                }
            },
            "2": {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 67
                }
            },
            "3": {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 79
                }
            },
            "4": {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 35
                }
            },
            "5": {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 29
                }
            },
            "6": {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 33
                }
            },
            "7": {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 29
                }
            },
            "8": {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 37
                }
            },
            "9": {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 27
                }
            },
            "10": {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 49
                }
            },
            "11": {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 39
                }
            },
            "12": {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 47
                }
            },
            "13": {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            "14": {
                start: {
                    line: 21,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 13
                }
            },
            "15": {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 111
                }
            },
            "16": {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 45
                }
            },
            "17": {
                start: {
                    line: 30,
                    column: 23
                },
                end: {
                    line: 30,
                    column: 56
                }
            },
            "18": {
                start: {
                    line: 31,
                    column: 20
                },
                end: {
                    line: 31,
                    column: 45
                }
            },
            "19": {
                start: {
                    line: 32,
                    column: 22
                },
                end: {
                    line: 32,
                    column: 64
                }
            },
            "20": {
                start: {
                    line: 33,
                    column: 22
                },
                end: {
                    line: 33,
                    column: 62
                }
            },
            "21": {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 9
                }
            },
            "22": {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 32
                }
            },
            "23": {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 9
                }
            },
            "24": {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 36
                }
            },
            "25": {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 9
                }
            },
            "26": {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 43,
                    column: 36
                }
            },
            "27": {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 63
                }
            },
            "28": {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 11
                }
            },
            "29": {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 13
                }
            },
            "30": {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 57,
                    column: 17
                }
            },
            "31": {
                start: {
                    line: 51,
                    column: 20
                },
                end: {
                    line: 56,
                    column: 23
                }
            },
            "32": {
                start: {
                    line: 52,
                    column: 24
                },
                end: {
                    line: 55,
                    column: 25
                }
            },
            "33": {
                start: {
                    line: 53,
                    column: 48
                },
                end: {
                    line: 53,
                    column: 117
                }
            },
            "34": {
                start: {
                    line: 54,
                    column: 28
                },
                end: {
                    line: 54,
                    column: 95
                }
            },
            "35": {
                start: {
                    line: 54,
                    column: 62
                },
                end: {
                    line: 54,
                    column: 93
                }
            },
            "36": {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 72
                }
            },
            "37": {
                start: {
                    line: 61,
                    column: 47
                },
                end: {
                    line: 61,
                    column: 70
                }
            },
            "38": {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 74
                }
            },
            "39": {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 35
                }
            },
            "40": {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 45
                }
            },
            "41": {
                start: {
                    line: 77,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 39
                }
            },
            "42": {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 38
                }
            },
            "43": {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 36
                }
            },
            "44": {
                start: {
                    line: 89,
                    column: 24
                },
                end: {
                    line: 92,
                    column: 31
                }
            },
            "45": {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 11
                }
            },
            "46": {
                start: {
                    line: 95,
                    column: 12
                },
                end: {
                    line: 96,
                    column: 64
                }
            },
            "47": {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 105,
                    column: 9
                }
            },
            "48": {
                start: {
                    line: 104,
                    column: 12
                },
                end: {
                    line: 104,
                    column: 19
                }
            },
            "49": {
                start: {
                    line: 106,
                    column: 22
                },
                end: {
                    line: 106,
                    column: 63
                }
            },
            "50": {
                start: {
                    line: 107,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 9
                }
            },
            "51": {
                start: {
                    line: 108,
                    column: 12
                },
                end: {
                    line: 108,
                    column: 82
                }
            },
            "52": {
                start: {
                    line: 113,
                    column: 8
                },
                end: {
                    line: 116,
                    column: 11
                }
            },
            "53": {
                start: {
                    line: 115,
                    column: 12
                },
                end: {
                    line: 115,
                    column: 117
                }
            },
            "54": {
                start: {
                    line: 120,
                    column: 24
                },
                end: {
                    line: 125,
                    column: 31
                }
            },
            "55": {
                start: {
                    line: 127,
                    column: 8
                },
                end: {
                    line: 141,
                    column: 27
                }
            },
            "56": {
                start: {
                    line: 128,
                    column: 33
                },
                end: {
                    line: 133,
                    column: 14
                }
            },
            "57": {
                start: {
                    line: 134,
                    column: 12
                },
                end: {
                    line: 140,
                    column: 13
                }
            },
            "58": {
                start: {
                    line: 135,
                    column: 16
                },
                end: {
                    line: 137,
                    column: 19
                }
            },
            "59": {
                start: {
                    line: 136,
                    column: 20
                },
                end: {
                    line: 136,
                    column: 71
                }
            },
            "60": {
                start: {
                    line: 139,
                    column: 16
                },
                end: {
                    line: 139,
                    column: 43
                }
            },
            "61": {
                start: {
                    line: 145,
                    column: 19
                },
                end: {
                    line: 145,
                    column: 32
                }
            },
            "62": {
                start: {
                    line: 146,
                    column: 20
                },
                end: {
                    line: 146,
                    column: 45
                }
            },
            "63": {
                start: {
                    line: 147,
                    column: 21
                },
                end: {
                    line: 147,
                    column: 107
                }
            },
            "64": {
                start: {
                    line: 149,
                    column: 8
                },
                end: {
                    line: 161,
                    column: 72
                }
            },
            "65": {
                start: {
                    line: 151,
                    column: 16
                },
                end: {
                    line: 159,
                    column: 17
                }
            },
            "66": {
                start: {
                    line: 152,
                    column: 36
                },
                end: {
                    line: 152,
                    column: 75
                }
            },
            "67": {
                start: {
                    line: 153,
                    column: 20
                },
                end: {
                    line: 156,
                    column: 21
                }
            },
            "68": {
                start: {
                    line: 154,
                    column: 24
                },
                end: {
                    line: 154,
                    column: 70
                }
            },
            "69": {
                start: {
                    line: 155,
                    column: 24
                },
                end: {
                    line: 155,
                    column: 74
                }
            },
            "70": {
                start: {
                    line: 158,
                    column: 20
                },
                end: {
                    line: 158,
                    column: 73
                }
            },
            "71": {
                start: {
                    line: 161,
                    column: 18
                },
                end: {
                    line: 161,
                    column: 70
                }
            },
            "72": {
                start: {
                    line: 165,
                    column: 8
                },
                end: {
                    line: 169,
                    column: 11
                }
            },
            "73": {
                start: {
                    line: 166,
                    column: 12
                },
                end: {
                    line: 166,
                    column: 61
                }
            },
            "74": {
                start: {
                    line: 168,
                    column: 12
                },
                end: {
                    line: 168,
                    column: 67
                }
            },
            "75": {
                start: {
                    line: 173,
                    column: 20
                },
                end: {
                    line: 173,
                    column: 43
                }
            },
            "76": {
                start: {
                    line: 174,
                    column: 20
                },
                end: {
                    line: 174,
                    column: 22
                }
            },
            "77": {
                start: {
                    line: 175,
                    column: 8
                },
                end: {
                    line: 175,
                    column: 54
                }
            },
            "78": {
                start: {
                    line: 176,
                    column: 8
                },
                end: {
                    line: 183,
                    column: 10
                }
            },
            "79": {
                start: {
                    line: 178,
                    column: 16
                },
                end: {
                    line: 178,
                    column: 65
                }
            },
            "80": {
                start: {
                    line: 181,
                    column: 16
                },
                end: {
                    line: 181,
                    column: 77
                }
            },
            "81": {
                start: {
                    line: 187,
                    column: 24
                },
                end: {
                    line: 192,
                    column: 31
                }
            },
            "82": {
                start: {
                    line: 194,
                    column: 8
                },
                end: {
                    line: 201,
                    column: 11
                }
            },
            "83": {
                start: {
                    line: 195,
                    column: 12
                },
                end: {
                    line: 198,
                    column: 15
                }
            },
            "84": {
                start: {
                    line: 196,
                    column: 16
                },
                end: {
                    line: 196,
                    column: 62
                }
            },
            "85": {
                start: {
                    line: 197,
                    column: 16
                },
                end: {
                    line: 197,
                    column: 49
                }
            },
            "86": {
                start: {
                    line: 205,
                    column: 0
                },
                end: {
                    line: 213,
                    column: 7
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 183
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 2
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 20,
                        column: 53
                    },
                    end: {
                        line: 20,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 71
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 20
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 14
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 29
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 48,
                        column: 54
                    },
                    end: {
                        line: 48,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 78
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                },
                line: 48
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 51,
                        column: 51
                    },
                    end: {
                        line: 51,
                        column: 52
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 63
                    },
                    end: {
                        line: 56,
                        column: 21
                    }
                },
                line: 51
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 54,
                        column: 56
                    },
                    end: {
                        line: 54,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 62
                    },
                    end: {
                        line: 54,
                        column: 93
                    }
                },
                line: 54
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 61,
                        column: 41
                    },
                    end: {
                        line: 61,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 47
                    },
                    end: {
                        line: 61,
                        column: 70
                    }
                },
                line: 61
            },
            "7": {
                name: "(anonymous_7)",
                decl: {
                    start: {
                        line: 64,
                        column: 4
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 23
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 64
            },
            "8": {
                name: "(anonymous_8)",
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 28
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                line: 68
            },
            "9": {
                name: "(anonymous_9)",
                decl: {
                    start: {
                        line: 72,
                        column: 4
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 38
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                line: 72
            },
            "10": {
                name: "(anonymous_10)",
                decl: {
                    start: {
                        line: 76,
                        column: 4
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 29
                    },
                    end: {
                        line: 78,
                        column: 5
                    }
                },
                line: 76
            },
            "11": {
                name: "(anonymous_11)",
                decl: {
                    start: {
                        line: 80,
                        column: 4
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 25
                    },
                    end: {
                        line: 82,
                        column: 5
                    }
                },
                line: 80
            },
            "12": {
                name: "(anonymous_12)",
                decl: {
                    start: {
                        line: 84,
                        column: 4
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 23
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                line: 84
            },
            "13": {
                name: "(anonymous_13)",
                decl: {
                    start: {
                        line: 88,
                        column: 4
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 22
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 88
            },
            "14": {
                name: "(anonymous_14)",
                decl: {
                    start: {
                        line: 94,
                        column: 42
                    },
                    end: {
                        line: 94,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 48
                    },
                    end: {
                        line: 97,
                        column: 9
                    }
                },
                line: 94
            },
            "15": {
                name: "(anonymous_15)",
                decl: {
                    start: {
                        line: 97,
                        column: 11
                    },
                    end: {
                        line: 97,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 97,
                        column: 23
                    },
                    end: {
                        line: 99,
                        column: 9
                    }
                },
                line: 97
            },
            "16": {
                name: "(anonymous_16)",
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 20
                    },
                    end: {
                        line: 110,
                        column: 5
                    }
                },
                line: 102
            },
            "17": {
                name: "(anonymous_17)",
                decl: {
                    start: {
                        line: 112,
                        column: 4
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 14
                    },
                    end: {
                        line: 117,
                        column: 5
                    }
                },
                line: 112
            },
            "18": {
                name: "(anonymous_18)",
                decl: {
                    start: {
                        line: 113,
                        column: 26
                    },
                    end: {
                        line: 113,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 113,
                        column: 32
                    },
                    end: {
                        line: 116,
                        column: 9
                    }
                },
                line: 113
            },
            "19": {
                name: "(anonymous_19)",
                decl: {
                    start: {
                        line: 119,
                        column: 4
                    },
                    end: {
                        line: 119,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 119,
                        column: 20
                    },
                    end: {
                        line: 142,
                        column: 5
                    }
                },
                line: 119
            },
            "20": {
                name: "(anonymous_20)",
                decl: {
                    start: {
                        line: 127,
                        column: 42
                    },
                    end: {
                        line: 127,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 127,
                        column: 48
                    },
                    end: {
                        line: 141,
                        column: 9
                    }
                },
                line: 127
            },
            "21": {
                name: "(anonymous_21)",
                decl: {
                    start: {
                        line: 135,
                        column: 61
                    },
                    end: {
                        line: 135,
                        column: 62
                    }
                },
                loc: {
                    start: {
                        line: 135,
                        column: 70
                    },
                    end: {
                        line: 137,
                        column: 17
                    }
                },
                line: 135
            },
            "22": {
                name: "(anonymous_22)",
                decl: {
                    start: {
                        line: 141,
                        column: 11
                    },
                    end: {
                        line: 141,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 141,
                        column: 23
                    },
                    end: {
                        line: 141,
                        column: 25
                    }
                },
                line: 141
            },
            "23": {
                name: "(anonymous_23)",
                decl: {
                    start: {
                        line: 144,
                        column: 4
                    },
                    end: {
                        line: 144,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 26
                    },
                    end: {
                        line: 162,
                        column: 5
                    }
                },
                line: 144
            },
            "24": {
                name: "(anonymous_24)",
                decl: {
                    start: {
                        line: 150,
                        column: 12
                    },
                    end: {
                        line: 150,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 150,
                        column: 24
                    },
                    end: {
                        line: 160,
                        column: 13
                    }
                },
                line: 150
            },
            "25": {
                name: "(anonymous_25)",
                decl: {
                    start: {
                        line: 161,
                        column: 12
                    },
                    end: {
                        line: 161,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 161,
                        column: 18
                    },
                    end: {
                        line: 161,
                        column: 70
                    }
                },
                line: 161
            },
            "26": {
                name: "(anonymous_26)",
                decl: {
                    start: {
                        line: 164,
                        column: 4
                    },
                    end: {
                        line: 164,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 164,
                        column: 11
                    },
                    end: {
                        line: 170,
                        column: 5
                    }
                },
                line: 164
            },
            "27": {
                name: "(anonymous_27)",
                decl: {
                    start: {
                        line: 165,
                        column: 40
                    },
                    end: {
                        line: 165,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 165,
                        column: 46
                    },
                    end: {
                        line: 167,
                        column: 9
                    }
                },
                line: 165
            },
            "28": {
                name: "(anonymous_28)",
                decl: {
                    start: {
                        line: 167,
                        column: 11
                    },
                    end: {
                        line: 167,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 167,
                        column: 17
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                },
                line: 167
            },
            "29": {
                name: "(anonymous_29)",
                decl: {
                    start: {
                        line: 172,
                        column: 4
                    },
                    end: {
                        line: 172,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 172,
                        column: 20
                    },
                    end: {
                        line: 184,
                        column: 5
                    }
                },
                line: 172
            },
            "30": {
                name: "(anonymous_30)",
                decl: {
                    start: {
                        line: 177,
                        column: 12
                    },
                    end: {
                        line: 177,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 177,
                        column: 18
                    },
                    end: {
                        line: 179,
                        column: 13
                    }
                },
                line: 177
            },
            "31": {
                name: "(anonymous_31)",
                decl: {
                    start: {
                        line: 180,
                        column: 12
                    },
                    end: {
                        line: 180,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 180,
                        column: 18
                    },
                    end: {
                        line: 182,
                        column: 13
                    }
                },
                line: 180
            },
            "32": {
                name: "(anonymous_32)",
                decl: {
                    start: {
                        line: 186,
                        column: 4
                    },
                    end: {
                        line: 186,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 186,
                        column: 18
                    },
                    end: {
                        line: 202,
                        column: 5
                    }
                },
                line: 186
            },
            "33": {
                name: "(anonymous_33)",
                decl: {
                    start: {
                        line: 194,
                        column: 42
                    },
                    end: {
                        line: 194,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 194,
                        column: 48
                    },
                    end: {
                        line: 199,
                        column: 9
                    }
                },
                line: 194
            },
            "34": {
                name: "(anonymous_34)",
                decl: {
                    start: {
                        line: 195,
                        column: 40
                    },
                    end: {
                        line: 195,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 195,
                        column: 46
                    },
                    end: {
                        line: 198,
                        column: 13
                    }
                },
                line: 195
            },
            "35": {
                name: "(anonymous_35)",
                decl: {
                    start: {
                        line: 199,
                        column: 11
                    },
                    end: {
                        line: 199,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 199,
                        column: 23
                    },
                    end: {
                        line: 201,
                        column: 9
                    }
                },
                line: 199
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                }, {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                }],
                line: 21
            },
            "1": {
                loc: {
                    start: {
                        line: 30,
                        column: 23
                    },
                    end: {
                        line: 30,
                        column: 56
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 30,
                        column: 23
                    },
                    end: {
                        line: 30,
                        column: 51
                    }
                }, {
                    start: {
                        line: 30,
                        column: 55
                    },
                    end: {
                        line: 30,
                        column: 56
                    }
                }],
                line: 30
            },
            "2": {
                loc: {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }, {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }],
                line: 35
            },
            "3": {
                loc: {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }, {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }],
                line: 39
            },
            "4": {
                loc: {
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                }, {
                    start: {
                        line: 42,
                        column: 8
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                }],
                line: 42
            },
            "5": {
                loc: {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 58,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 58,
                        column: 13
                    }
                }, {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 58,
                        column: 13
                    }
                }],
                line: 49
            },
            "6": {
                loc: {
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 57,
                        column: 17
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 57,
                        column: 17
                    }
                }, {
                    start: {
                        line: 50,
                        column: 16
                    },
                    end: {
                        line: 57,
                        column: 17
                    }
                }],
                line: 50
            },
            "7": {
                loc: {
                    start: {
                        line: 52,
                        column: 24
                    },
                    end: {
                        line: 55,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 52,
                        column: 24
                    },
                    end: {
                        line: 55,
                        column: 25
                    }
                }, {
                    start: {
                        line: 52,
                        column: 24
                    },
                    end: {
                        line: 55,
                        column: 25
                    }
                }],
                line: 52
            },
            "8": {
                loc: {
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 105,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 105,
                        column: 9
                    }
                }, {
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 105,
                        column: 9
                    }
                }],
                line: 103
            },
            "9": {
                loc: {
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                }, {
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                }],
                line: 107
            },
            "10": {
                loc: {
                    start: {
                        line: 134,
                        column: 12
                    },
                    end: {
                        line: 140,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 134,
                        column: 12
                    },
                    end: {
                        line: 140,
                        column: 13
                    }
                }, {
                    start: {
                        line: 134,
                        column: 12
                    },
                    end: {
                        line: 140,
                        column: 13
                    }
                }],
                line: 134
            },
            "11": {
                loc: {
                    start: {
                        line: 153,
                        column: 20
                    },
                    end: {
                        line: 156,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 153,
                        column: 20
                    },
                    end: {
                        line: 156,
                        column: 21
                    }
                }, {
                    start: {
                        line: 153,
                        column: 20
                    },
                    end: {
                        line: 156,
                        column: 21
                    }
                }],
                line: 153
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0,
            "61": 0,
            "62": 0,
            "63": 0,
            "64": 0,
            "65": 0,
            "66": 0,
            "67": 0,
            "68": 0,
            "69": 0,
            "70": 0,
            "71": 0,
            "72": 0,
            "73": 0,
            "74": 0,
            "75": 0,
            "76": 0,
            "77": 0,
            "78": 0,
            "79": 0,
            "80": 0,
            "81": 0,
            "82": 0,
            "83": 0,
            "84": 0,
            "85": 0,
            "86": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0],
            "4": [0, 0],
            "5": [0, 0],
            "6": [0, 0],
            "7": [0, 0],
            "8": [0, 0],
            "9": [0, 0],
            "10": [0, 0],
            "11": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EventDetailsViewController = function () {
    function EventDetailsViewController(RoomsTable, PromotersTable, EventsTable, EventCategoriesTable, $state, $mdToast, $mdDialog, $scope, $rootScope, $http, EventPhotosTable, HighlightsTable, AppSettings) {
        _classCallCheck(this, EventDetailsViewController);

        cov_1ofpaxtmw1.f[0]++;
        cov_1ofpaxtmw1.s[0]++;

        this.AppSettings = AppSettings;
        /* eslint-disable camelcase */
        cov_1ofpaxtmw1.s[1]++;
        this.rooms = RoomsTable.$search({ page_size: 9999 });
        cov_1ofpaxtmw1.s[2]++;
        this.promoters = PromotersTable.$search({ page_size: 9999 });
        cov_1ofpaxtmw1.s[3]++;
        this.eventCategories = EventCategoriesTable.$search({ page_size: 9999 });
        cov_1ofpaxtmw1.s[4]++;
        this.$mdDialog = $mdDialog;

        cov_1ofpaxtmw1.s[5]++;
        this.$state = $state;
        cov_1ofpaxtmw1.s[6]++;
        this.$mdToast = $mdToast;
        cov_1ofpaxtmw1.s[7]++;
        this.$scope = $scope;
        cov_1ofpaxtmw1.s[8]++;
        this.$rootScope = $rootScope;
        cov_1ofpaxtmw1.s[9]++;
        this.$http = $http;
        cov_1ofpaxtmw1.s[10]++;
        this.EventPhotosTable = EventPhotosTable;
        cov_1ofpaxtmw1.s[11]++;
        this.EventsTable = EventsTable;
        cov_1ofpaxtmw1.s[12]++;
        this.HighlightsTable = HighlightsTable;

        /* eslint-disable prefer-arrow-callback */
        cov_1ofpaxtmw1.s[13]++;
        $scope.$watch('$ctrl.event.$dirty().length', function (length) {
            cov_1ofpaxtmw1.f[1]++;
            cov_1ofpaxtmw1.s[14]++;

            if (length) {
                cov_1ofpaxtmw1.b[0][0]++;
                cov_1ofpaxtmw1.s[15]++;

                $rootScope.preventNavigation("As alterações não foram gravadas. Têm certeza que deseja sair?");
            } else {
                cov_1ofpaxtmw1.b[0][1]++;
                cov_1ofpaxtmw1.s[16]++;

                $rootScope.allowNavigation();
            }
        });
    }

    _createClass(EventDetailsViewController, [{
        key: "$onInit",
        value: function $onInit() {
            var _this = this;

            cov_1ofpaxtmw1.f[2]++;

            var secNum = (cov_1ofpaxtmw1.s[17]++, (cov_1ofpaxtmw1.b[1][0]++, this.event.estimatedDuration) || (cov_1ofpaxtmw1.b[1][1]++, 0)); // don't forget the second param
            var hours = (cov_1ofpaxtmw1.s[18]++, Math.floor(secNum / 3600));
            var minutes = (cov_1ofpaxtmw1.s[19]++, Math.floor((secNum - hours * 3600) / 60));
            var seconds = (cov_1ofpaxtmw1.s[20]++, secNum - hours * 3600 - minutes * 60);

            cov_1ofpaxtmw1.s[21]++;
            if (hours < 10) {
                cov_1ofpaxtmw1.b[2][0]++;
                cov_1ofpaxtmw1.s[22]++;

                /* eslint-disable prefer-template */
                hours = "0" + hours;
            } else {
                cov_1ofpaxtmw1.b[2][1]++;
            }
            cov_1ofpaxtmw1.s[23]++;
            if (minutes < 10) {
                cov_1ofpaxtmw1.b[3][0]++;
                cov_1ofpaxtmw1.s[24]++;

                minutes = "0" + minutes;
            } else {
                cov_1ofpaxtmw1.b[3][1]++;
            }
            cov_1ofpaxtmw1.s[25]++;
            if (seconds < 10) {
                cov_1ofpaxtmw1.b[4][0]++;
                cov_1ofpaxtmw1.s[26]++;

                seconds = "0" + seconds;
            } else {
                cov_1ofpaxtmw1.b[4][1]++;
            }

            cov_1ofpaxtmw1.s[27]++;
            this.event.estimatedDurationUI = hours + ':' + minutes;

            cov_1ofpaxtmw1.s[28]++;
            this.$scope.$watch('$ctrl.newPhoto.photoUrl', function (newValue, oldValue) {
                cov_1ofpaxtmw1.f[3]++;
                cov_1ofpaxtmw1.s[29]++;

                if (newValue !== oldValue) {
                    cov_1ofpaxtmw1.b[5][0]++;
                    cov_1ofpaxtmw1.s[30]++;

                    if (Array.isArray(_this.newPhoto.photoUrl)) {
                        cov_1ofpaxtmw1.b[6][0]++;
                        cov_1ofpaxtmw1.s[31]++;

                        _this.newPhoto.photoUrl.forEach(function (photoUrl) {
                            cov_1ofpaxtmw1.f[4]++;
                            cov_1ofpaxtmw1.s[32]++;

                            if (angular.isString(photoUrl)) {
                                cov_1ofpaxtmw1.b[7][0]++;

                                var newPhotoModel = (cov_1ofpaxtmw1.s[33]++, _this.event.$photos.$build({ photoUrl: photoUrl, event: _this.event.id }));
                                cov_1ofpaxtmw1.s[34]++;
                                newPhotoModel.$save().$then(function () {
                                    cov_1ofpaxtmw1.f[5]++;
                                    cov_1ofpaxtmw1.s[35]++;
                                    return _this._buildEventPhoto(photoUrl);
                                });
                            } else {
                                cov_1ofpaxtmw1.b[7][1]++;
                            }
                        });
                    } else {
                        cov_1ofpaxtmw1.b[6][1]++;
                    }
                } else {
                    cov_1ofpaxtmw1.b[5][1]++;
                }
            });

            cov_1ofpaxtmw1.s[36]++;
            this.event.$load('photos').$then(function () {
                cov_1ofpaxtmw1.f[6]++;
                cov_1ofpaxtmw1.s[37]++;
                return _this._buildEventPhoto();
            });
        }
    }, {
        key: "_buildEventPhoto",
        value: function _buildEventPhoto() {
            cov_1ofpaxtmw1.f[7]++;
            cov_1ofpaxtmw1.s[38]++;

            this.newPhoto = this.event.$photos.$build({ event: this.event.id });
        }
    }, {
        key: "toggleEventVisibility",
        value: function toggleEventVisibility() {
            cov_1ofpaxtmw1.f[8]++;
            cov_1ofpaxtmw1.s[39]++;

            this.autoSave('isVisible');
        }
    }, {
        key: "toggleEventHomeAgendaVisibility",
        value: function toggleEventHomeAgendaVisibility() {
            cov_1ofpaxtmw1.f[9]++;
            cov_1ofpaxtmw1.s[40]++;

            this.autoSave('isVisibleHomeAgenda');
        }
    }, {
        key: "toggleEventHighlighted",
        value: function toggleEventHighlighted() {
            cov_1ofpaxtmw1.f[10]++;
            cov_1ofpaxtmw1.s[41]++;

            this.autoSave('isHighlighted');
        }
    }, {
        key: "toggleIsArchivable",
        value: function toggleIsArchivable() {
            cov_1ofpaxtmw1.f[11]++;
            cov_1ofpaxtmw1.s[42]++;

            this.autoSave('isArchivable');
        }
    }, {
        key: "toggleIsArchived",
        value: function toggleIsArchived() {
            cov_1ofpaxtmw1.f[12]++;
            cov_1ofpaxtmw1.s[43]++;

            this.autoSave('isArchived');
        }
    }, {
        key: "createHighlight",
        value: function createHighlight() {
            var _this2 = this;

            cov_1ofpaxtmw1.f[13]++;

            var confirm = (cov_1ofpaxtmw1.s[44]++, this.$mdDialog.confirm().title('Criar destaque do evento?').ok('Criar destaque').cancel('Cancelar'));

            cov_1ofpaxtmw1.s[45]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1ofpaxtmw1.f[14]++;
                cov_1ofpaxtmw1.s[46]++;

                _this2.$state.go('highlightDetailsView', {
                    id: null, event: _this2.event.id, type: 'EVENT' });
            }, function () {
                cov_1ofpaxtmw1.f[15]++;
            });
        }
    }, {
        key: "parseDuration",
        value: function parseDuration() {
            cov_1ofpaxtmw1.f[16]++;
            cov_1ofpaxtmw1.s[47]++;

            if (!this.event.estimatedDurationUI) {
                cov_1ofpaxtmw1.b[8][0]++;
                cov_1ofpaxtmw1.s[48]++;

                return;
            } else {
                cov_1ofpaxtmw1.b[8][1]++;
            }
            var times = (cov_1ofpaxtmw1.s[49]++, this.event.estimatedDurationUI.split(':'));
            cov_1ofpaxtmw1.s[50]++;
            if (times.length === 2) {
                cov_1ofpaxtmw1.b[9][0]++;
                cov_1ofpaxtmw1.s[51]++;

                this.event.estimatedDuration = times[1] * 60 + times[0] * 60 * 60;
            } else {
                cov_1ofpaxtmw1.b[9][1]++;
            }
        }
    }, {
        key: "preview",
        value: function preview() {
            var _this3 = this;

            cov_1ofpaxtmw1.f[17]++;
            cov_1ofpaxtmw1.s[52]++;

            this.save().$then(function () {
                cov_1ofpaxtmw1.f[18]++;
                cov_1ofpaxtmw1.s[53]++;

                /* eslint-disable angular/window-service */
                window.open(_this3.$rootScope.AppSettings.siteUrl + '/evento/' + _this3.event.slug + '/', 'coliseu-preview');
            });
        }
    }, {
        key: "duplicate",
        value: function duplicate(type) {
            var _this4 = this;

            cov_1ofpaxtmw1.f[19]++;

            var confirm = (cov_1ofpaxtmw1.s[54]++, this.$mdDialog.confirm().title('Duplicar evento?').textContent('Um novo evento será criado com os dados replicados. Eventos replicados ficarão em rascunho e deverão ser publicados manualmente.').ariaLabel('Duplicar evento?').ok('Duplicar').cancel('Cancelar'));

            cov_1ofpaxtmw1.s[55]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1ofpaxtmw1.f[20]++;

                var newEventData = (cov_1ofpaxtmw1.s[56]++, Object.assign({}, _this4.event, {
                    id: null,
                    $pk: null,
                    name: _this4.event.name + ' (Duplicado)',
                    isVisible: false
                }));
                cov_1ofpaxtmw1.s[57]++;
                if (type === "simple") {
                    cov_1ofpaxtmw1.b[10][0]++;
                    cov_1ofpaxtmw1.s[58]++;

                    _this4.EventsTable.$create(newEventData).$then(function (event) {
                        cov_1ofpaxtmw1.f[21]++;
                        cov_1ofpaxtmw1.s[59]++;

                        _this4.$state.go('eventDetailsView', { id: event.id });
                    });
                } else {
                    cov_1ofpaxtmw1.b[10][1]++;
                    cov_1ofpaxtmw1.s[60]++;

                    _this4.duplicateWithPhotos();
                }
            }, function () {
                cov_1ofpaxtmw1.f[22]++;
            });
        }
    }, {
        key: "duplicateWithPhotos",
        value: function duplicateWithPhotos() {
            var _this5 = this;

            cov_1ofpaxtmw1.f[23]++;

            var id = (cov_1ofpaxtmw1.s[61]++, this.event.id);
            var url = (cov_1ofpaxtmw1.s[62]++, this.AppSettings.graphUrl);
            var data = (cov_1ofpaxtmw1.s[63]++, "{\"query\":\"mutation {duplicateOneEvent(input: { event: { id: " + id + " } }) { id   } } \"}");
            // os headers são automaticamente adicionados
            cov_1ofpaxtmw1.s[64]++;
            this.$http.post(url, data).then(function (response) {
                cov_1ofpaxtmw1.f[24]++;
                cov_1ofpaxtmw1.s[65]++;

                try {
                    var eventId = (cov_1ofpaxtmw1.s[66]++, response.data.data.duplicateOneEvent.id);
                    cov_1ofpaxtmw1.s[67]++;
                    if (eventId) {
                        cov_1ofpaxtmw1.b[11][0]++;
                        cov_1ofpaxtmw1.s[68]++;

                        _this5.$mdToast.showSimple("Evento duplicado.");
                        cov_1ofpaxtmw1.s[69]++;
                        _this5.$state.go('eventDetailsView', { id: eventId });
                    } else {
                        cov_1ofpaxtmw1.b[11][1]++;
                    }
                } catch (e) {
                    cov_1ofpaxtmw1.s[70]++;

                    _this5.$mdToast.showSimple("Erro ao duplicar evento.");
                }
            }, function () {
                cov_1ofpaxtmw1.f[25]++;
                cov_1ofpaxtmw1.s[71]++;
                return _this5.$mdToast.showSimple("Erro ao duplicar evento.");
            });
        }
    }, {
        key: "save",
        value: function save() {
            var _this6 = this;

            cov_1ofpaxtmw1.f[26]++;
            cov_1ofpaxtmw1.s[72]++;

            return this.event.$save().$then(function () {
                cov_1ofpaxtmw1.f[27]++;
                cov_1ofpaxtmw1.s[73]++;

                _this6.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_1ofpaxtmw1.f[28]++;
                cov_1ofpaxtmw1.s[74]++;

                _this6.$mdToast.showSimple('Erro ao guardar alterações');
            });
        }
    }, {
        key: "autoSave",
        value: function autoSave(field) {
            var _this7 = this;

            cov_1ofpaxtmw1.f[29]++;

            var url = (cov_1ofpaxtmw1.s[75]++, this.event.$url() + '/');
            var obj = (cov_1ofpaxtmw1.s[76]++, {});
            cov_1ofpaxtmw1.s[77]++;
            obj[field.toUnderscore()] = this.event[field];
            cov_1ofpaxtmw1.s[78]++;
            this.$http.patch(url, obj).then(function () {
                cov_1ofpaxtmw1.f[30]++;
                cov_1ofpaxtmw1.s[79]++;

                _this7.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_1ofpaxtmw1.f[31]++;
                cov_1ofpaxtmw1.s[80]++;

                _this7.$mdToast.showSimple('As alterações não estão gravadas');
            });
        }
    }, {
        key: "deleteEvent",
        value: function deleteEvent() {
            var _this8 = this;

            cov_1ofpaxtmw1.f[32]++;

            var confirm = (cov_1ofpaxtmw1.s[81]++, this.$mdDialog.confirm().title('Têm certeza que deseja apagar esse evento?').textContent('Essa ação é irreversível').ariaLabel('Delete event').ok('Apagar').cancel('Cancelar'));

            cov_1ofpaxtmw1.s[82]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1ofpaxtmw1.f[33]++;
                cov_1ofpaxtmw1.s[83]++;

                _this8.event.$destroy().$then(function () {
                    cov_1ofpaxtmw1.f[34]++;
                    cov_1ofpaxtmw1.s[84]++;

                    _this8.$mdToast.showSimple('Evento eliminado!');
                    cov_1ofpaxtmw1.s[85]++;
                    _this8.$state.go('eventsListView');
                });
            }, function () {
                cov_1ofpaxtmw1.f[35]++;
            });
        }
    }]);

    return EventDetailsViewController;
}();

cov_1ofpaxtmw1.s[86]++;


angular.module('app').component('eventDetailsView', {
    templateUrl: 'app/components/eventDetailsView/eventDetailsView.html',
    controller: EventDetailsViewController,
    bindings: {
        event: '<'
    }
});