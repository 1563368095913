'use strict';

var cov_255wooygjl = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/highlightDetailsView/highlightDetailsView.js',
        hash = '78830a0e5b4af912abf66793c11786321c03dfd1',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/highlightDetailsView/highlightDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 33
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 29
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 37
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 46
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 111
                }
            },
            '10': {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 17,
                    column: 45
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 11
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 13
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 57
                }
            },
            '14': {
                start: {
                    line: 25,
                    column: 51
                },
                end: {
                    line: 25,
                    column: 55
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 24
                },
                end: {
                    line: 36,
                    column: 31
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 11
                }
            },
            '17': {
                start: {
                    line: 39,
                    column: 33
                },
                end: {
                    line: 44,
                    column: 14
                }
            },
            '18': {
                start: {
                    line: 45,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 15
                }
            },
            '19': {
                start: {
                    line: 46,
                    column: 16
                },
                end: {
                    line: 46,
                    column: 71
                }
            },
            '20': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 11
                }
            },
            '21': {
                start: {
                    line: 55,
                    column: 12
                },
                end: {
                    line: 55,
                    column: 61
                }
            },
            '22': {
                start: {
                    line: 57,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 67
                }
            },
            '23': {
                start: {
                    line: 62,
                    column: 20
                },
                end: {
                    line: 62,
                    column: 47
                }
            },
            '24': {
                start: {
                    line: 63,
                    column: 20
                },
                end: {
                    line: 63,
                    column: 22
                }
            },
            '25': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 58
                }
            },
            '26': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 72,
                    column: 10
                }
            },
            '27': {
                start: {
                    line: 67,
                    column: 16
                },
                end: {
                    line: 67,
                    column: 65
                }
            },
            '28': {
                start: {
                    line: 70,
                    column: 16
                },
                end: {
                    line: 70,
                    column: 77
                }
            },
            '29': {
                start: {
                    line: 76,
                    column: 24
                },
                end: {
                    line: 81,
                    column: 31
                }
            },
            '30': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 11
                }
            },
            '31': {
                start: {
                    line: 84,
                    column: 12
                },
                end: {
                    line: 87,
                    column: 15
                }
            },
            '32': {
                start: {
                    line: 85,
                    column: 16
                },
                end: {
                    line: 85,
                    column: 64
                }
            },
            '33': {
                start: {
                    line: 86,
                    column: 16
                },
                end: {
                    line: 86,
                    column: 53
                }
            },
            '34': {
                start: {
                    line: 94,
                    column: 0
                },
                end: {
                    line: 102,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 102
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 13,
                        column: 57
                    },
                    end: {
                        line: 13,
                        column: 58
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 75
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 13
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 14
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 22
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 61
                    },
                    end: {
                        line: 23,
                        column: 62
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 67
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 45
                    },
                    end: {
                        line: 25,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 51
                    },
                    end: {
                        line: 25,
                        column: 55
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 16
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 38,
                        column: 42
                    },
                    end: {
                        line: 38,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 48
                    },
                    end: {
                        line: 48,
                        column: 9
                    }
                },
                line: 38
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 45,
                        column: 60
                    },
                    end: {
                        line: 45,
                        column: 61
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 69
                    },
                    end: {
                        line: 47,
                        column: 13
                    }
                },
                line: 45
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 48,
                        column: 11
                    },
                    end: {
                        line: 48,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 23
                    },
                    end: {
                        line: 50,
                        column: 9
                    }
                },
                line: 48
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 11
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                line: 53
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 54,
                        column: 44
                    },
                    end: {
                        line: 54,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 50
                    },
                    end: {
                        line: 56,
                        column: 9
                    }
                },
                line: 54
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 56,
                        column: 11
                    },
                    end: {
                        line: 56,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 17
                    },
                    end: {
                        line: 58,
                        column: 9
                    }
                },
                line: 56
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 61,
                        column: 4
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 20
                    },
                    end: {
                        line: 73,
                        column: 5
                    }
                },
                line: 61
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 66,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 18
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                },
                line: 66
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 69,
                        column: 12
                    },
                    end: {
                        line: 69,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 18
                    },
                    end: {
                        line: 71,
                        column: 13
                    }
                },
                line: 69
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 75,
                        column: 4
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 75,
                        column: 18
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                line: 75
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 83,
                        column: 42
                    },
                    end: {
                        line: 83,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 48
                    },
                    end: {
                        line: 88,
                        column: 9
                    }
                },
                line: 83
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 84,
                        column: 44
                    },
                    end: {
                        line: 84,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 50
                    },
                    end: {
                        line: 87,
                        column: 13
                    }
                },
                line: 84
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 88,
                        column: 11
                    },
                    end: {
                        line: 88,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 23
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                },
                line: 88
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                }, {
                    start: {
                        line: 14,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                }],
                line: 14
            },
            '1': {
                loc: {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                }, {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                }],
                line: 24
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HighlightDetailsViewController = function () {
    function HighlightDetailsViewController(EventsTable, $state, $mdToast, $mdDialog, $scope, $rootScope, $http, HighlightsTable) {
        _classCallCheck(this, HighlightDetailsViewController);

        cov_255wooygjl.f[0]++;
        cov_255wooygjl.s[0]++;

        this.$mdDialog = $mdDialog;

        cov_255wooygjl.s[1]++;
        this.$state = $state;
        cov_255wooygjl.s[2]++;
        this.$mdToast = $mdToast;
        cov_255wooygjl.s[3]++;
        this.$scope = $scope;
        cov_255wooygjl.s[4]++;
        this.$rootScope = $rootScope;
        cov_255wooygjl.s[5]++;
        this.$http = $http;
        cov_255wooygjl.s[6]++;
        this.HighlightTable = HighlightsTable;

        /* eslint-disable prefer-arrow-callback */
        cov_255wooygjl.s[7]++;
        $scope.$watch('$ctrl.highlight.$dirty().length', function (length) {
            cov_255wooygjl.f[1]++;
            cov_255wooygjl.s[8]++;

            if (length) {
                cov_255wooygjl.b[0][0]++;
                cov_255wooygjl.s[9]++;

                $rootScope.preventNavigation("As alterações não foram gravadas. Têm certeza que deseja sair?");
            } else {
                cov_255wooygjl.b[0][1]++;
                cov_255wooygjl.s[10]++;

                $rootScope.allowNavigation();
            }
        });
    }

    _createClass(HighlightDetailsViewController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_255wooygjl.f[2]++;
            cov_255wooygjl.s[11]++;

            this.$scope.$watch('$ctrl.highlight.highlightImage', function () {
                cov_255wooygjl.f[3]++;
                cov_255wooygjl.s[12]++;

                if (angular.isString(_this.highlight.highlightImage)) {
                    cov_255wooygjl.b[1][0]++;
                    cov_255wooygjl.s[13]++;

                    _this.highlight.$save().$then(function () {
                        cov_255wooygjl.f[4]++;
                        cov_255wooygjl.s[14]++;
                        return null;
                    });
                } else {
                    cov_255wooygjl.b[1][1]++;
                }
            });
        }
    }, {
        key: 'duplicate',
        value: function duplicate() {
            var _this2 = this;

            cov_255wooygjl.f[5]++;

            var confirm = (cov_255wooygjl.s[15]++, this.$mdDialog.confirm().title('Duplicar destaque?').textContent('Um novo destaque será criado com os dados replicados.').ariaLabel('Duplicar destaque?').ok('Duplicar').cancel('Cancelar'));

            cov_255wooygjl.s[16]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_255wooygjl.f[6]++;

                var newEventData = (cov_255wooygjl.s[17]++, Object.assign({}, _this2.highlight, {
                    id: null,
                    $pk: null,
                    /* eslint-disable prefer-template */
                    name: _this2.highlight.name + ' (Duplicado)'
                }));
                cov_255wooygjl.s[18]++;
                _this2.HighlightTable.$create(newEventData).$then(function (event) {
                    cov_255wooygjl.f[7]++;
                    cov_255wooygjl.s[19]++;

                    _this2.$state.go('highlightDetailsView', { id: event.id });
                });
            }, function () {
                cov_255wooygjl.f[8]++;
            });
        }
    }, {
        key: 'save',
        value: function save() {
            var _this3 = this;

            cov_255wooygjl.f[9]++;
            cov_255wooygjl.s[20]++;

            return this.highlight.$save().$then(function () {
                cov_255wooygjl.f[10]++;
                cov_255wooygjl.s[21]++;

                _this3.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_255wooygjl.f[11]++;
                cov_255wooygjl.s[22]++;

                _this3.$mdToast.showSimple('Erro ao guardar alterações');
            });
        }
    }, {
        key: 'autoSave',
        value: function autoSave(field) {
            var _this4 = this;

            cov_255wooygjl.f[12]++;

            var url = (cov_255wooygjl.s[23]++, this.highlight.$url() + '/');
            var obj = (cov_255wooygjl.s[24]++, {});
            cov_255wooygjl.s[25]++;
            obj[field.toUnderscore()] = this.highlight[field];
            cov_255wooygjl.s[26]++;
            this.$http.patch(url, obj).then(function () {
                cov_255wooygjl.f[13]++;
                cov_255wooygjl.s[27]++;

                _this4.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_255wooygjl.f[14]++;
                cov_255wooygjl.s[28]++;

                _this4.$mdToast.showSimple('As alterações não estão gravadas');
            });
        }
    }, {
        key: 'deleteEvent',
        value: function deleteEvent() {
            var _this5 = this;

            cov_255wooygjl.f[15]++;

            var confirm = (cov_255wooygjl.s[29]++, this.$mdDialog.confirm().title('Têm certeza que deseja apagar esse destaque?').textContent('Essa ação é irreversível').ariaLabel('Delete event').ok('Apagar').cancel('Cancelar'));

            cov_255wooygjl.s[30]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_255wooygjl.f[16]++;
                cov_255wooygjl.s[31]++;

                _this5.highlight.$destroy().$then(function () {
                    cov_255wooygjl.f[17]++;
                    cov_255wooygjl.s[32]++;

                    _this5.$mdToast.showSimple('Destaque eliminado!');
                    cov_255wooygjl.s[33]++;
                    _this5.$state.go('highlightsListView');
                });
            }, function () {
                cov_255wooygjl.f[18]++;
            });
        }
    }]);

    return HighlightDetailsViewController;
}();

cov_255wooygjl.s[34]++;


angular.module('app').component('highlightDetailsView', {
    templateUrl: 'app/components/highlightDetailsView/highlightDetailsView.html',
    controller: HighlightDetailsViewController,
    bindings: {
        highlight: '<'
    }
});