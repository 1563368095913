'use strict';

var cov_gcdm1agrt = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/shared/js/ORM/tables.posts.factory.js',
        hash = 'b0738803e05cb77da2ecdecaf43a6bb1228b3e59',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/shared/js/ORM/tables.posts.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 39,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 24
                },
                end: {
                    line: 15,
                    column: 65
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 20
                },
                end: {
                    line: 17,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 20,
                    column: 20
                },
                end: {
                    line: 20,
                    column: 43
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 39,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 24
                    },
                    end: {
                        line: 12,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 32
                    },
                    end: {
                        line: 18,
                        column: 17
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 24
                    },
                    end: {
                        line: 19,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 33
                    },
                    end: {
                        line: 21,
                        column: 17
                    }
                },
                line: 19
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                }, {
                    start: {
                        line: 13,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                }],
                line: 13
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_gcdm1agrt.s[0]++;
(function () {
    'use strict';

    cov_gcdm1agrt.f[0]++;
    cov_gcdm1agrt.s[1]++;
    angular.module('app').factory('PostsTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_gcdm1agrt.f[1]++;
        cov_gcdm1agrt.s[2]++;

        return restmod.model('/posts').mix('MixinContainable', {
            publishedAt: {
                encode: function encode(date) {
                    cov_gcdm1agrt.f[2]++;
                    cov_gcdm1agrt.s[3]++;

                    if (date) {
                        cov_gcdm1agrt.b[0][0]++;
                        cov_gcdm1agrt.s[4]++;

                        /* eslint-disable no-undef */
                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_gcdm1agrt.b[0][1]++;
                    }
                    cov_gcdm1agrt.s[5]++;
                    return null;
                },
                decode: function decode(value) {
                    cov_gcdm1agrt.f[3]++;
                    cov_gcdm1agrt.s[6]++;

                    return new Date(value);
                }
            },
            $config: {
                contains: {
                    related: {
                        type: 'hasMany',
                        table: 'RelatedPostsTable',
                        foreignKey: 'post'
                    },
                    photos: {
                        type: 'hasMany',
                        table: 'PostPhotosTable',
                        foreignKey: 'post'
                    }
                }
            }
        }).mix('DirtyModel');
    }
})();