'use strict';
/* eslint-disable angular/di */

var cov_2jjy6nubon = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/shared/js/ngWigClearStyles.js',
        hash = '1b935cfb70569e83e1bb246cf8a3f52713ffa13f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/shared/js/ngWigClearStyles.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 0
                },
                end: {
                    line: 24,
                    column: 7
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 87
                }
            },
            '2': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 14
                }
            },
            '3': {
                start: {
                    line: 19,
                    column: 26
                },
                end: {
                    line: 19,
                    column: 55
                }
            },
            '4': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 45
                }
            },
            '5': {
                start: {
                    line: 21,
                    column: 16
                },
                end: {
                    line: 21,
                    column: 47
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 4,
                        column: 37
                    },
                    end: {
                        line: 4,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 4,
                        column: 69
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 4
            },
            '1': {
                name: 'controller',
                decl: {
                    start: {
                        line: 13,
                        column: 29
                    },
                    end: {
                        line: 13,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 42
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 13
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 31
                    },
                    end: {
                        line: 15,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 44
                    },
                    end: {
                        line: 22,
                        column: 13
                    }
                },
                line: 15
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2jjy6nubon.s[0]++;
angular.module('ngWig').config(['ngWigToolbarProvider', function (ngWigToolbarProvider) {
    cov_2jjy6nubon.f[0]++;
    cov_2jjy6nubon.s[1]++;

    ngWigToolbarProvider.addCustomButton('clear-styles', 'nw-clear-styles-button');
}]).component('nwClearStylesButton', {
    bindings: {
        editMode: '=',
        disabled: '=',
        content: '='
    },
    template: '<button ng-click="$ctrl.clearStyles($event)" ng-disabled="$ctrl.editMode || $ctrl.disabled" class="nw-button clear-styles" title="Clear Styles">Clear Styles</button>',
    controller: function controller() {
        cov_2jjy6nubon.f[1]++;
        cov_2jjy6nubon.s[2]++;

        /* eslint-disable no-unused-vars */
        this.clearStyles = function (e) {
            cov_2jjy6nubon.f[2]++;

            // create a virutal element to manipulate the content of the editor
            /* eslint-disable angular/document-service */
            /* eslint-disable no-var */
            var div = (cov_2jjy6nubon.s[3]++, document.createElement('div'));
            cov_2jjy6nubon.s[4]++;
            div.innerHTML = this.content;
            cov_2jjy6nubon.s[5]++;
            this.content = div.textContent;
        };
    }
});