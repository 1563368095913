'use strict';

var cov_1jfeg75wu = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/postDetailsView/postDetailsView.js',
        hash = 'f96a4d445ec171e47b25eec5848c04f562b3b44b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/postDetailsView/postDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 27
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 37
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 35
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 29
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 29
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 111
                }
            },
            '10': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 45
                }
            },
            '11': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 35
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 13
                }
            },
            '14': {
                start: {
                    line: 26,
                    column: 16
                },
                end: {
                    line: 33,
                    column: 17
                }
            },
            '15': {
                start: {
                    line: 27,
                    column: 20
                },
                end: {
                    line: 32,
                    column: 23
                }
            },
            '16': {
                start: {
                    line: 28,
                    column: 24
                },
                end: {
                    line: 31,
                    column: 25
                }
            },
            '17': {
                start: {
                    line: 29,
                    column: 48
                },
                end: {
                    line: 29,
                    column: 114
                }
            },
            '18': {
                start: {
                    line: 30,
                    column: 28
                },
                end: {
                    line: 30,
                    column: 90
                }
            },
            '19': {
                start: {
                    line: 30,
                    column: 62
                },
                end: {
                    line: 30,
                    column: 88
                }
            },
            '20': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 66
                }
            },
            '21': {
                start: {
                    line: 38,
                    column: 46
                },
                end: {
                    line: 38,
                    column: 64
                }
            },
            '22': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 71
                }
            },
            '23': {
                start: {
                    line: 47,
                    column: 22
                },
                end: {
                    line: 49,
                    column: 10
                }
            },
            '24': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 47
                }
            },
            '25': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 26
                }
            },
            '26': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 31
                }
            },
            '27': {
                start: {
                    line: 58,
                    column: 20
                },
                end: {
                    line: 58,
                    column: 42
                }
            },
            '28': {
                start: {
                    line: 59,
                    column: 20
                },
                end: {
                    line: 59,
                    column: 22
                }
            },
            '29': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 53
                }
            },
            '30': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 10
                }
            },
            '31': {
                start: {
                    line: 63,
                    column: 16
                },
                end: {
                    line: 63,
                    column: 65
                }
            },
            '32': {
                start: {
                    line: 66,
                    column: 16
                },
                end: {
                    line: 66,
                    column: 77
                }
            },
            '33': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 11
                }
            },
            '34': {
                start: {
                    line: 73,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 61
                }
            },
            '35': {
                start: {
                    line: 75,
                    column: 12
                },
                end: {
                    line: 75,
                    column: 67
                }
            },
            '36': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 11
                }
            },
            '37': {
                start: {
                    line: 81,
                    column: 24
                },
                end: {
                    line: 81,
                    column: 91
                }
            },
            '38': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 35
                }
            },
            '39': {
                start: {
                    line: 87,
                    column: 24
                },
                end: {
                    line: 92,
                    column: 31
                }
            },
            '40': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 21
                }
            },
            '41': {
                start: {
                    line: 95,
                    column: 12
                },
                end: {
                    line: 98,
                    column: 15
                }
            },
            '42': {
                start: {
                    line: 96,
                    column: 16
                },
                end: {
                    line: 96,
                    column: 63
                }
            },
            '43': {
                start: {
                    line: 97,
                    column: 16
                },
                end: {
                    line: 97,
                    column: 48
                }
            },
            '44': {
                start: {
                    line: 103,
                    column: 24
                },
                end: {
                    line: 106,
                    column: 31
                }
            },
            '45': {
                start: {
                    line: 108,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 21
                }
            },
            '46': {
                start: {
                    line: 109,
                    column: 12
                },
                end: {
                    line: 110,
                    column: 61
                }
            },
            '47': {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 115,
                    column: 85
                }
            },
            '48': {
                start: {
                    line: 120,
                    column: 0
                },
                end: {
                    line: 129,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 81
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 12,
                        column: 52
                    },
                    end: {
                        line: 12,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 62
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 12
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 14
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 24,
                        column: 54
                    },
                    end: {
                        line: 24,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 78
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 27,
                        column: 51
                    },
                    end: {
                        line: 27,
                        column: 52
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 63
                    },
                    end: {
                        line: 32,
                        column: 21
                    }
                },
                line: 27
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 56
                    },
                    end: {
                        line: 30,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 62
                    },
                    end: {
                        line: 30,
                        column: 88
                    }
                },
                line: 30
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 38,
                        column: 40
                    },
                    end: {
                        line: 38,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 46
                    },
                    end: {
                        line: 38,
                        column: 64
                    }
                },
                line: 38
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 41,
                        column: 4
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 18
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                line: 41
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 18
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 46
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 47,
                        column: 38
                    },
                    end: {
                        line: 47,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 49
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 47
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 27
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 53
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 20
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 57
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 62,
                        column: 12
                    },
                    end: {
                        line: 62,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 18
                    },
                    end: {
                        line: 64,
                        column: 13
                    }
                },
                line: 62
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 65,
                        column: 12
                    },
                    end: {
                        line: 65,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 18
                    },
                    end: {
                        line: 67,
                        column: 13
                    }
                },
                line: 65
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 71,
                        column: 4
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 11
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                line: 71
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 72,
                        column: 39
                    },
                    end: {
                        line: 72,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 45
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                },
                line: 72
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 74,
                        column: 11
                    },
                    end: {
                        line: 74,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 17
                    },
                    end: {
                        line: 76,
                        column: 9
                    }
                },
                line: 74
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 79,
                        column: 4
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 14
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                line: 79
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 80,
                        column: 26
                    },
                    end: {
                        line: 80,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 32
                    },
                    end: {
                        line: 83,
                        column: 9
                    }
                },
                line: 80
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 86,
                        column: 4
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 17
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 86
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 94,
                        column: 42
                    },
                    end: {
                        line: 94,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 48
                    },
                    end: {
                        line: 99,
                        column: 9
                    }
                },
                line: 94
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 95,
                        column: 39
                    },
                    end: {
                        line: 95,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 95,
                        column: 45
                    },
                    end: {
                        line: 98,
                        column: 13
                    }
                },
                line: 95
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 99,
                        column: 11
                    },
                    end: {
                        line: 99,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 99,
                        column: 17
                    },
                    end: {
                        line: 99,
                        column: 19
                    }
                },
                line: 99
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 22
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                line: 102
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 108,
                        column: 42
                    },
                    end: {
                        line: 108,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 108,
                        column: 48
                    },
                    end: {
                        line: 111,
                        column: 9
                    }
                },
                line: 108
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 111,
                        column: 11
                    },
                    end: {
                        line: 111,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 111,
                        column: 17
                    },
                    end: {
                        line: 111,
                        column: 19
                    }
                },
                line: 111
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 114,
                        column: 4
                    },
                    end: {
                        line: 114,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 24
                    },
                    end: {
                        line: 116,
                        column: 5
                    }
                },
                line: 114
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 13
            },
            '1': {
                loc: {
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                }, {
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                }],
                line: 25
            },
            '2': {
                loc: {
                    start: {
                        line: 26,
                        column: 16
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 26,
                        column: 16
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                }, {
                    start: {
                        line: 26,
                        column: 16
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                }],
                line: 26
            },
            '3': {
                loc: {
                    start: {
                        line: 28,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 28,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                }, {
                    start: {
                        line: 28,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                }],
                line: 28
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PostDetailsViewController = function () {
    function PostDetailsViewController($mdToast, $http, $rootScope, $window, $mdDialog, $state, $scope) {
        _classCallCheck(this, PostDetailsViewController);

        cov_1jfeg75wu.f[0]++;
        cov_1jfeg75wu.s[0]++;

        this.$mdToast = $mdToast;
        cov_1jfeg75wu.s[1]++;
        this.$http = $http;
        cov_1jfeg75wu.s[2]++;
        this.$rootScope = $rootScope;
        cov_1jfeg75wu.s[3]++;
        this.$window = $window;
        cov_1jfeg75wu.s[4]++;
        this.$mdDialog = $mdDialog;
        cov_1jfeg75wu.s[5]++;
        this.$state = $state;
        cov_1jfeg75wu.s[6]++;
        this.$scope = $scope;

        cov_1jfeg75wu.s[7]++;
        $scope.$watch('$ctrl.post.$dirty().length', function (length) {
            cov_1jfeg75wu.f[1]++;
            cov_1jfeg75wu.s[8]++;

            if (length) {
                cov_1jfeg75wu.b[0][0]++;
                cov_1jfeg75wu.s[9]++;

                $rootScope.preventNavigation("As alterações não foram gravadas. Têm certeza que deseja sair?");
            } else {
                cov_1jfeg75wu.b[0][1]++;
                cov_1jfeg75wu.s[10]++;

                $rootScope.allowNavigation();
            }
        });
    }

    _createClass(PostDetailsViewController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_1jfeg75wu.f[2]++;
            cov_1jfeg75wu.s[11]++;

            this.post.$load('related');

            cov_1jfeg75wu.s[12]++;
            this.$scope.$watch('$ctrl.newPhoto.photoUrl', function (newValue, oldValue) {
                cov_1jfeg75wu.f[3]++;
                cov_1jfeg75wu.s[13]++;

                if (newValue !== oldValue) {
                    cov_1jfeg75wu.b[1][0]++;
                    cov_1jfeg75wu.s[14]++;

                    if (Array.isArray(_this.newPhoto.photoUrl)) {
                        cov_1jfeg75wu.b[2][0]++;
                        cov_1jfeg75wu.s[15]++;

                        _this.newPhoto.photoUrl.forEach(function (photoUrl) {
                            cov_1jfeg75wu.f[4]++;
                            cov_1jfeg75wu.s[16]++;

                            if (angular.isString(photoUrl)) {
                                cov_1jfeg75wu.b[3][0]++;

                                var newPhotoModel = (cov_1jfeg75wu.s[17]++, _this.post.$photos.$build({ photoUrl: photoUrl, post: _this.post.id }));
                                cov_1jfeg75wu.s[18]++;
                                newPhotoModel.$save().$then(function () {
                                    cov_1jfeg75wu.f[5]++;
                                    cov_1jfeg75wu.s[19]++;
                                    return _this._buildPhoto(photoUrl);
                                });
                            } else {
                                cov_1jfeg75wu.b[3][1]++;
                            }
                        });
                    } else {
                        cov_1jfeg75wu.b[2][1]++;
                    }
                } else {
                    cov_1jfeg75wu.b[1][1]++;
                }
            });

            cov_1jfeg75wu.s[20]++;
            this.post.$load('photos').$then(function () {
                cov_1jfeg75wu.f[6]++;
                cov_1jfeg75wu.s[21]++;
                return _this._buildPhoto();
            });
        }
    }, {
        key: '_buildPhoto',
        value: function _buildPhoto() {
            cov_1jfeg75wu.f[7]++;
            cov_1jfeg75wu.s[22]++;

            this.newPhoto = this.post.$photos.$build({ post: this.post.id });
        }

        // HACK huge, refactor asap

    }, {
        key: 'getName',
        value: function getName(post) {
            cov_1jfeg75wu.f[8]++;

            var found = (cov_1jfeg75wu.s[23]++, this.posts.find(function (element) {
                cov_1jfeg75wu.f[9]++;
                cov_1jfeg75wu.s[24]++;

                return element.id === post.related;
            }));
            cov_1jfeg75wu.s[25]++;
            return found.name;
        }
    }, {
        key: 'togglePostVisibility',
        value: function togglePostVisibility() {
            cov_1jfeg75wu.f[10]++;
            cov_1jfeg75wu.s[26]++;

            this.autoSave('state');
        }
    }, {
        key: 'autoSave',
        value: function autoSave(field) {
            var _this2 = this;

            cov_1jfeg75wu.f[11]++;

            var url = (cov_1jfeg75wu.s[27]++, this.post.$url() + '/');
            var obj = (cov_1jfeg75wu.s[28]++, {});
            cov_1jfeg75wu.s[29]++;
            obj[field.toUnderscore()] = this.post[field];
            cov_1jfeg75wu.s[30]++;
            this.$http.patch(url, obj).then(function () {
                cov_1jfeg75wu.f[12]++;
                cov_1jfeg75wu.s[31]++;

                _this2.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_1jfeg75wu.f[13]++;
                cov_1jfeg75wu.s[32]++;

                _this2.$mdToast.showSimple('As alterações não estão gravadas');
            });
        }
    }, {
        key: 'save',
        value: function save() {
            var _this3 = this;

            cov_1jfeg75wu.f[14]++;
            cov_1jfeg75wu.s[33]++;

            return this.post.$save().$then(function () {
                cov_1jfeg75wu.f[15]++;
                cov_1jfeg75wu.s[34]++;

                _this3.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_1jfeg75wu.f[16]++;
                cov_1jfeg75wu.s[35]++;

                _this3.$mdToast.showSimple('Erro ao guardar alterações');
            });
        }
    }, {
        key: 'preview',
        value: function preview() {
            var _this4 = this;

            cov_1jfeg75wu.f[17]++;
            cov_1jfeg75wu.s[36]++;

            this.save().$then(function () {
                cov_1jfeg75wu.f[18]++;

                var url = (cov_1jfeg75wu.s[37]++, _this4.$rootScope.AppSettings.siteUrl + '/noticia/' + _this4.post.slug + '/');
                cov_1jfeg75wu.s[38]++;
                _this4.$window.open(url);
            });
        }
    }, {
        key: 'deletePost',
        value: function deletePost() {
            var _this5 = this;

            cov_1jfeg75wu.f[19]++;

            var confirm = (cov_1jfeg75wu.s[39]++, this.$mdDialog.confirm().title('Tem certeza que deseja apagar esta notícia?').textContent('Esta ação é irreversível.').ariaLabel('Delete post').ok('Apagar').cancel('Cancelar'));

            cov_1jfeg75wu.s[40]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1jfeg75wu.f[20]++;
                cov_1jfeg75wu.s[41]++;

                _this5.post.$destroy().$then(function () {
                    cov_1jfeg75wu.f[21]++;
                    cov_1jfeg75wu.s[42]++;

                    _this5.$mdToast.showSimple('Notícia eliminada!');
                    cov_1jfeg75wu.s[43]++;
                    _this5.$state.go('postsListView');
                });
            }, function () {
                cov_1jfeg75wu.f[22]++;
            });
        }
    }, {
        key: 'createHighlight',
        value: function createHighlight() {
            var _this6 = this;

            cov_1jfeg75wu.f[23]++;

            var confirm = (cov_1jfeg75wu.s[44]++, this.$mdDialog.confirm().title('Criar destaque da notícia?').ok('Criar destaque').cancel('Cancelar'));

            cov_1jfeg75wu.s[45]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1jfeg75wu.f[24]++;
                cov_1jfeg75wu.s[46]++;

                _this6.$state.go('highlightDetailsView', {
                    id: null, post: _this6.post.id, type: 'NEWS' });
            }, function () {
                cov_1jfeg75wu.f[25]++;
            });
        }
    }, {
        key: 'createRelatedPost',
        value: function createRelatedPost() {
            cov_1jfeg75wu.f[26]++;
            cov_1jfeg75wu.s[47]++;

            this.post.$related.$create({ post: this.post.id, related: this.selectedPost });
        }
    }]);

    return PostDetailsViewController;
}();

cov_1jfeg75wu.s[48]++;


angular.module('app').component('postDetailsView', {
    templateUrl: 'app/components/postDetailsView/postDetailsView.html',
    controller: PostDetailsViewController,
    bindings: {
        post: '<',
        posts: '<'
    }
});