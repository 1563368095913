'use strict';

var cov_1xdmqspnky = function () {
    var path = '/home/lobo/repos/coliseu-admin/src/app/components/promoterDetailsView/promoterDetailsView.js',
        hash = '17bb4f3b20127f31e52f3a23eaf4ca6a350f5196',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-admin/src/app/components/promoterDetailsView/promoterDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 12
                },
                end: {
                    line: 8,
                    column: 61
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 67
                }
            },
            '4': {
                start: {
                    line: 18,
                    column: 0
                },
                end: {
                    line: 26,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 26
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 4
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 11
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 7,
                        column: 36
                    },
                    end: {
                        line: 7,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 42
                    },
                    end: {
                        line: 9,
                        column: 9
                    }
                },
                line: 7
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 9,
                        column: 11
                    },
                    end: {
                        line: 9,
                        column: 12
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 17
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 9
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 15
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 14
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PromoterDetailsViewController = function () {
    function PromoterDetailsViewController($mdToast) {
        _classCallCheck(this, PromoterDetailsViewController);

        cov_1xdmqspnky.f[0]++;
        cov_1xdmqspnky.s[0]++;

        this.$mdToast = $mdToast;
    }

    _createClass(PromoterDetailsViewController, [{
        key: 'save',
        value: function save() {
            var _this = this;

            cov_1xdmqspnky.f[1]++;
            cov_1xdmqspnky.s[1]++;

            this.promoter.$save().$then(function () {
                cov_1xdmqspnky.f[2]++;
                cov_1xdmqspnky.s[2]++;

                _this.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_1xdmqspnky.f[3]++;
                cov_1xdmqspnky.s[3]++;

                _this.$mdToast.showSimple('Erro ao guardar alterações');
            });
        }
    }, {
        key: 'autoSave',
        value: function autoSave() {
            cov_1xdmqspnky.f[4]++;
        }
    }]);

    return PromoterDetailsViewController;
}();

cov_1xdmqspnky.s[4]++;


angular.module('app').component('promoterDetailsView', {
    templateUrl: 'app/components/promoterDetailsView/promoterDetailsView.html',
    controller: PromoterDetailsViewController,
    bindings: {
        promoter: '<'
    }
});