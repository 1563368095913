"use strict";

var cov_y6rgwa3j3 = function () {
    var path = "/home/lobo/repos/coliseu-admin/src/app/components/signatureDetailsView/signatureDetailsView.js",
        hash = "0c5bbf9438300816ad1e297e9f289f200694c8c7",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/coliseu-admin/src/app/components/signatureDetailsView/signatureDetailsView.js",
        statementMap: {
            "0": {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 55
                }
            },
            "1": {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 27
                }
            },
            "2": {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 39
                }
            },
            "3": {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 33
                }
            },
            "4": {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 31
                }
            },
            "5": {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 70
                }
            },
            "6": {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 76
                }
            },
            "7": {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 10
                }
            },
            "8": {
                start: {
                    line: 25,
                    column: 18
                },
                end: {
                    line: 25,
                    column: 66
                }
            },
            "9": {
                start: {
                    line: 26,
                    column: 18
                },
                end: {
                    line: 26,
                    column: 72
                }
            },
            "10": {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 50
                }
            },
            "11": {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 86
                }
            },
            "12": {
                start: {
                    line: 39,
                    column: 0
                },
                end: {
                    line: 44,
                    column: 7
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 76
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 3
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 14
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 11
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 16,
                        column: 4
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 11
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 16
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 18
                    },
                    end: {
                        line: 25,
                        column: 66
                    }
                },
                line: 25
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 26,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 18
                    },
                    end: {
                        line: 26,
                        column: 72
                    }
                },
                line: 26
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 12
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                line: 30
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 34,
                        column: 4
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 14
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                line: 34
            }
        },
        branchMap: {},
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0
        },
        b: {},
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SignatureDetailsViewController = function () {
    function SignatureDetailsViewController(ConfigurationsTable, $http, AppSettings, $mdToast, $window) {
        _classCallCheck(this, SignatureDetailsViewController);

        cov_y6rgwa3j3.f[0]++;
        cov_y6rgwa3j3.s[0]++;

        this.ConfigurationsTable = ConfigurationsTable;
        cov_y6rgwa3j3.s[1]++;
        this.$http = $http;
        cov_y6rgwa3j3.s[2]++;
        this.AppSettings = AppSettings;
        cov_y6rgwa3j3.s[3]++;
        this.$mdToast = $mdToast;
        cov_y6rgwa3j3.s[4]++;
        this.$window = $window;
    }

    _createClass(SignatureDetailsViewController, [{
        key: "$onInit",
        value: function $onInit() {
            cov_y6rgwa3j3.f[1]++;
            cov_y6rgwa3j3.s[5]++;

            this.signature = this.ConfigurationsTable.$find("EMAIL_SIGN");
            cov_y6rgwa3j3.s[6]++;
            this.default = this.ConfigurationsTable.$find("EMAIL_DEFAULT_SIGN");
        }
    }, {
        key: "save",
        value: function save() {
            var _this = this;

            cov_y6rgwa3j3.f[2]++;
            cov_y6rgwa3j3.s[7]++;

            this.$http({
                method: 'PUT',
                /* eslint-disable prefer-template */
                url: this.AppSettings.apiUrl + '/update-signature/',
                data: {
                    signature: this.signature.value
                }
            }).then(function () {
                cov_y6rgwa3j3.f[3]++;
                cov_y6rgwa3j3.s[8]++;
                return _this.$mdToast.showSimple('Alterações guardadas');
            }, function () {
                cov_y6rgwa3j3.f[4]++;
                cov_y6rgwa3j3.s[9]++;
                return _this.$mdToast.showSimple('Erro ao guardar alterações');
            });
        }
    }, {
        key: "reset",
        value: function reset() {
            cov_y6rgwa3j3.f[5]++;
            cov_y6rgwa3j3.s[10]++;

            this.signature.value = this.default.value;
        }
    }, {
        key: "preview",
        value: function preview() {
            cov_y6rgwa3j3.f[6]++;
            cov_y6rgwa3j3.s[11]++;

            this.$window.open('https://www.coliseu.pt/assets/newsletter/assinatura.html');
        }
    }]);

    return SignatureDetailsViewController;
}();

cov_y6rgwa3j3.s[12]++;


angular.module('app').component('signatureDetailsView', {
    templateUrl: 'app/components/signatureDetailsView/signatureDetailsView.html',
    controller: SignatureDetailsViewController
});